import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchModels = async () => {
  const uri = `models`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateModel = async (data: any) => {
  const uri = `models`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateModel = async ({ data, _id }: any) => {
  const uri = `models/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteModel = async ({ _id }: any) => {
  const uri = `models/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchModels,
  handleCreateModel,
  handleUpdateModel,
  handleDeleteModel,
};
