import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchGenerics = async () => {
  const uri = `generics`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateGeneric = async (data: any) => {
  const uri = `generics`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateGeneric = async ({ data, _id }: any) => {
  const uri = `generics/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteGeneric = async ({ _id }: any) => {
  const uri = `generics/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchGenerics,
  handleCreateGeneric,
  handleUpdateGeneric,
  handleDeleteGeneric,
};
