import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  message,
  DatePicker,
} from "antd";
import { handleCreateAttendance } from "../../../../api/attendance";
import moment from "moment";
import disabledDate from "../../../../utils/disabledDate";

export default function CreateAttendance({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleCreateAttendance,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["attendances"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { date } = await form.getFieldsValue();
    const data = {
      date,
      fdate: Number(moment(new Date(date)).format("YYYYMMDD")),
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
      day: new Date(date).getDate(),
    };

    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Attendance"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createAttendance"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Create
        </Button>,
      ]}
    >
      <Form
        id="createAttendance"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="date"
              label="Attendance Date"
              rules={[
                {
                  required: true,
                  message: "Please enter attendance date",
                },
              ]}
            >
              <DatePicker
                placeholder="Attendance Date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
