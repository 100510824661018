import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { handleUpdateJobcardItemTask } from "../../../../api/jobcards";
import { useEffect } from "react";

export default function UpdateItemTask({ isVisible, setVisible, item, task }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateJobcardItemTask,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
      queryClient.invalidateQueries({ queryKey: ["jobcards", item?.jobcard] });
      setVisible(false);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { description, expectedHours } = await form.getFieldsValue();
    const data = {
      description,
      expectedHours,
    };
    await mutate({ _id: item?.jobcard, itemId: item?._id, taskId: task?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ description: task?.description });
    form.setFieldsValue({ expectedHours: task?.expectedHours });
  }, [task]);

  return (
    <Modal
      open={isVisible}
      title="Update Sub-Task"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateItemTask"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateItemTask"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Input type="text" placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="expectedHours"
              label={"Expected Hours"}
              rules={[
                {
                  required: true,
                  message: "Please enter expected hours",
                },
              ]}
            >
              <Input type="number" placeholder="Expected Hours" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
