import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, message } from "antd";
import { useEffect } from "react";
import { handleDeleteJobcard } from "../../../../api/jobcards";
import { useNavigate } from "react-router";

export default function DeleteJobcard({ isVisible, setVisible, jobcard }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteJobcard,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
      navigate("/manage/jobcards");
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { description } = await form.getFieldsValue();
    const data = {
      description,
    };
    await mutate({ data, _id: jobcard?._id });
    setVisible(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [jobcard, form]);

  return (
    <Modal
      open={isVisible}
      title="Delete Jobcard"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="deleteJobcard"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteJobcard"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-automark-dark font-bold mx-1">Delete</span>
            Jobcard
          </div>
          <div className="font-bold text-lg">WIP : {jobcard?.wip}?</div>
        </div>
      </Form>
    </Modal>
  );
}
