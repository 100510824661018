import { Button, Modal, Form, Row, Col, Select, Input, message } from "antd";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchStations } from "../../../../api/stations";
import { handleFetchSkills } from "../../../../api/skills";
import { handleUpdateTechnician } from "../../../../api/technicians";

const { Option } = Select;

export default function UpdateTechnician({
  isVisible,
  setVisible,
  technician,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: stations } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  const { data: skills } = useQuery({
    queryKey: ["skills"],
    queryFn: handleFetchSkills,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateTechnician,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      setVisible(false);
      queryClient.invalidateQueries({ queryKey: ["technicians"] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = { ...values, targetHrs: Number(values.targetHrs) };
    await mutate({ _id: technician?._id, data });
  };
  const stationOptions = stations?.data?.map((level: any, index: number) => (
    <Option value={level?._id} key={index}>
      {level?.name}
    </Option>
  ));
  const skillOptions = skills?.data?.map((skill: any, index: number) => (
    <Option value={skill?._id} key={index}>
      {skill?.description}
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ name: technician?.name });
    form.setFieldsValue({ mobile: technician?.mobile });
    form.setFieldsValue({ email: technician?.email });
    form.setFieldsValue({ isActive: technician?.mobile });
    form.setFieldsValue({ status: technician?.status });
    form.setFieldsValue({ code: technician?.code });
    form.setFieldsValue({ empID: technician?.empID });
    form.setFieldsValue({ station: technician?.station?._id });
    form.setFieldsValue({ skill: technician?.skill?._id });
    form.setFieldsValue({ targetHrs: technician?.targetHrs });
  }, [technician]);

  return (
    <Modal
      open={isVisible}
      title="Update Technician"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateTechnician"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateTechnician"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Name"}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  required: false,
                  message: "Please enter email",
                },
              ]}
            >
              <Input type="text" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label={"Mobile"}
              rules={[
                {
                  required: false,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input type="text" placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="station"
              label={"Station"}
              rules={[
                {
                  required: true,
                  message: "Please select station",
                },
              ]}
            >
              <Select placeholder="Station">{stationOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="empID"
              label={"KJL ID"}
              rules={[
                {
                  required: false,
                  message: "Please enter KJL ID",
                },
              ]}
            >
              <Input type="text" placeholder="KJL ID" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="code"
              label={"Code"}
              rules={[
                {
                  required: true,
                  message: "Please enter code",
                },
              ]}
            >
              <Input type="text" placeholder="Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="skill"
              label={"Skill"}
              rules={[
                {
                  required: true,
                  message: "Please select skill",
                },
              ]}
            >
              <Select placeholder="Skill">{skillOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="targetHrs"
              label={"Target Hours"}
              rules={[
                {
                  required: false,
                  message: "Please enter target hours",
                },
              ]}
            >
              <Input type="text" placeholder="Target Hours" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
