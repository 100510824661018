import { Button, Form, Input, Table } from "antd";
import BackNav from "../../../components/toolbars/BackNav";
import { useEffect, useState } from "react";
import CreateJobcard from "./components/CreateJobcard";
import UpdateJobcard from "./components/UpdateJobcard";
import DeleteJobcard from "./components/DeleteJobcard";
import Something from "../../../components/shared/Something";
import { useNavigate } from "react-router";
import { handleFetchJobcards } from "../../../api/jobcards";
import { useQuery } from "@tanstack/react-query";
import Moment from "react-moment";
import { RiFilterOffLine } from "react-icons/ri";
import JobTime from "../../jobcards/components/JobTime";

const { Search } = Input;

export default function Jobcards() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const initials = {
    wip: "",
    reg: "",
  };

  const groupedTech = (items: any) => {
    const uniqueTechnicians: any = [];
    const technicianIds = new Set();

    items.forEach((item: any): any => {
      const technician = item?.technician;
      if (!technicianIds.has(technician?._id)) {
        technicianIds.add(technician?._id);
        uniqueTechnicians.push(technician);
      }
    });

    return uniqueTechnicians;
  };

  const [filters, setFilters] = useState<any>({ ...initials });

  const params = `wip=${filters?.wip}&reg=${filters?.reg}`;

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  });

  const {
    data: jobcards,
    refetch,
    isLoading,
    isFetching,
    isFetched,
    isError,
  } = useQuery({
    queryKey: ["jobcards", pagination.page, pagination.limit, params],
    queryFn: () =>
      handleFetchJobcards({
        page: pagination.page,
        limit: pagination.limit,
        params,
      }),
  });

  const handlePageChange = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      page: e.current,
      limit: e.pageSize,
    }));
  };

  const handleRefetch = async () => {
    await refetch();
  };

  const clearAllFilters = async () => {
    form.resetFields();
    setFilters({ ...initials });
    handleRefetch();
  };

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.limit - pagination?.limit + index + 1}
        </span>
      ),
    },
    {
      title: "DESCRIPTION",
      width: 60,
      render: (record: any) => (
        <div className="">
          <div className="text-gray-700 font-bold truncate">
            {record?.description || "Jobcard"}
          </div>
          <div className="">WIP NO: {record?.wip || "-"}</div>
        </div>
      ),
    },
    {
      title: "TIME ALLOCATION",
      width: 100,
      render: (record: any) => (
        <div className="text-gray-700">
          <JobTime items={record?.items} expected={record?.expectedHours} />
        </div>
      ),
    },
    {
      title: "CUSTOMER",
      width: 100,
      render: (record: any) => (
        <div className="text-gray-700">
          <div className="">
            <span className="font-bold">{record?.customer}</span>
          </div>
          <div className="">
            <span>Vehicle </span>
            <span>: {record?.registration}</span>
          </div>
        </div>
      ),
    },
    {
      title: "DURATION",
      width: 80,
      render: (record: any) => (
        <div className="text-gray-700">
          <div className="">
            <span>Start Date</span>
            <span>
              : <Moment format="Do MMM YYYY">{record?.datein}</Moment>
            </span>
          </div>
          <div className="">
            <span>End Date</span>
            <span>
              : <Moment format="Do MMM YYYY">{record?.dateout}</Moment>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      width: 60,
      render: (record: any) => (
        <div className="text-gray-700">
          <div className="">
            <span>Station: {record?.station?.name}</span>
            <div className="text-black flex">
              <div className="capitalize mr-1">{record?.status}</div>
              {record?.isClosed ? (
                <div className="text-red-500">Closed</div>
              ) : (
                <div className="text-green-500">Open</div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "TECHNICIAN(S)",
      width: 60,
      render: (record: any) => (
        <div className="">
          {groupedTech(record?.items)?.map((technician: any, index: number) => (
            <div className="flex" key={index}>
              <div className="mr-2">
                {technician?.avatar && (
                  <img
                    src={technician?.avatar}
                    style={{
                      width: 40,
                      backgroundColor: "#ECECEC",
                      borderRadius: 10,
                    }}
                    className="w-12 h-12 aspect-[4/4] border object-cover"
                  />
                )}
              </div>
              {technician?.name ? (
                <span>{technician?.name}</span>
              ) : (
                <span className="text-red-500">Not Assigned</span>
              )}
            </div>
          ))}
        </div>
      ),
    },

    {
      title: "CREATED",
      width: 50,
      render: (record: any) => (
        <div className="">
          <div className="text-gray-700 flex flex-col">
            <div className="font-bold">
              <Moment format="Do MMM, YYYY">{record?.createdAt}</Moment>
            </div>
            <Moment fromNow>{record?.createdAt}</Moment>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPagination({ ...pagination, total: jobcards?.payload?.total });
  }, [jobcards, isFetched]);

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Jobcards</div>
            <div className="text-gray-600">Manage Jobcards</div>
          </div>
          <div className="">
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>
        <div className="">
          <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
            <Form layout="inline" form={form}>
              <Form.Item name="wip">
                <Search
                  placeholder="Search by WIP No."
                  allowClear
                  onSearch={(e: any) => {
                    setFilters({ ...filters, wip: e });
                  }}
                />
              </Form.Item>
              <Form.Item name="reg">
                <Search
                  placeholder="Search by Vehicle Reg."
                  allowClear
                  onSearch={(e: any) => {
                    setFilters({ ...filters, reg: e });
                  }}
                />
              </Form.Item>
              <Button
                className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
                onClick={() => {
                  clearAllFilters();
                }}
              >
                <RiFilterOffLine color="gray" />
              </Button>
            </Form>
          </div>
        </div>

        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <Table
              columns={columns}
              dataSource={jobcards?.payload?.data}
              size="small"
              bordered
              scroll={{ y: 525 }}
              loading={isLoading}
              rowKey="id"
              onRow={(record: any) => {
                return {
                  onClick: () => {
                    navigate(`/manage/jobcards/${record?._id}`);
                  },
                };
              }}
              pagination={{
                current: pagination?.page,
                pageSize: pagination?.limit,
                total: pagination?.total,
              }}
              onChange={(e: any) => handlePageChange(e)}
              rowClassName={"hover:cursor-pointer hover:bg-red-50"}
            />
          </div>
        )}
      </div>

      <CreateJobcard
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={refetch}
      />

      <UpdateJobcard
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
      />

      <DeleteJobcard
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={selected}
      />
    </div>
  );
}
