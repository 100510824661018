import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useEffect } from "react";
import { handleUpdateRTSCode } from "../../../../api/rtscodes";
import TextArea from "antd/es/input/TextArea";
import { handleFetchModels } from "../../../../api/models";

const { Option } = Select;

export default function UpdateCode({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateRTSCode,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["rtscodes"] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { code, model, duration, description } = await form.getFieldsValue();
    const data = {
      code,
      model,
      duration: Number(duration || 0),
      description: description || "-",
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  const modelOptions = models?.data?.map((level: any, index: number) => (
    <Option value={level?._id} key={index}>
      {level?.name}
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ code: selected?.code });
    form.setFieldsValue({ price: selected?.price });
    form.setFieldsValue({ model: selected?.model?._id });
    form.setFieldsValue({ duration: selected?.duration });
    form.setFieldsValue({ description: selected?.description });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update RTS-CODE"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateRTSCode"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateRTSCode"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  required: true,
                  message: "Please enter code",
                },
              ]}
            >
              <Input type="text" placeholder="Code" />
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="type"
              label={"Type"}
              rules={[
                {
                  required: true,
                  message: "Please select type",
                },
              ]}
            >
              <Select placeholder="Model">
                <Option value="Service">Service</Option>
                <Option value="Repair">Repair</Option>
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="model"
              label={"Model"}
              rules={[
                {
                  required: true,
                  message: "Please select model",
                },
              ]}
            >
              <Select placeholder="Model">{modelOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[
                {
                  required: true,
                  message: "Please enter duration",
                },
              ]}
            >
              <Input type="number" placeholder="Duration" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
