import { useContext, useEffect, useState } from "react";

import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import putData from "../../../services/putData";
import { handleFetchRoles } from "../../../api/roles";
import { useQuery } from "@tanstack/react-query";
const { Option } = Select;

export default function UpdateDetails({ isVisible, setVisible }: any) {
  const { user, auth, setAuth } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: handleFetchRoles,
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const uri = `users/${auth?._id}`;
      const response = await putData({ data: values, uri });
      message.success("Updated successfully");
      setAuth(response.data);
      setVisible(false);

      setLoading(false);
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const roleOptions = roles?.data?.map((role: any, index: number) => (
    <Option value={role?._id} key={index}>
      <div className="capitalize">{role?.name}</div>
    </Option>
  ));

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ role: auth?.role?._id });
  }, [form, user]);

  return (
    <Modal
      open={isVisible}
      title={<div className="flex">Update Details</div>}
      onCancel={() => setVisible(!isVisible)}
      width={680}
      footer={[
        <Button key="back" danger onClick={() => setVisible(!isVisible)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateDetails"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateDetails"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label={"Name"}
              initialValue={auth?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter firstname",
                },
              ]}
            >
              <Input placeholder="First Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label={"Email"}
              initialValue={user?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                type={"email"}
                placeholder="Email"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label={"Mobile"}
              initialValue={auth?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter lastname",
                },
              ]}
            >
              <Input placeholder="Last Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="role"
              label={"Designation / Role"}
              initialValue={auth?.role?._id}
              rules={[
                {
                  required: true,
                  message: "Please select level",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
