import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchTechnicians = async () => {
  const uri = `technicians`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateTechnician = async (data: any) => {
  const uri = `technicians`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateTechnician = async ({ _id, data }: any) => {
  const uri = `technicians/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleUpdateTechnicianStatus = async ({ _id, data }: any) => {
  const uri = `technicians/${_id}/status`;
  const response = putData({ data, uri });
  return response;
};

const handleUpdateTechnicianLeave = async ({ _id, data }: any) => {
  const uri = `technicians/${_id}/leave`;
  const response = putData({ data, uri });
  return response;
};


const handleDeleteTechnician = async ({_id, data}: any) => {
  const uri = `technicians/${_id}`;
  const response = deleteData({ data, uri });
  return response;
};


export {
  handleFetchTechnicians,
  handleCreateTechnician,
  handleUpdateTechnician,
  handleDeleteTechnician,
  handleUpdateTechnicianStatus,
  handleUpdateTechnicianLeave
};
