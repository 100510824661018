import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import fetchTrace from "../services/fetchTrace";
import patchData from "../services/patchData";
import postData from "../services/postData";
import putData from "../services/putData";

const handleFetchJobcards = async ({ page, limit, params }: any) => {
  const uri = `jobcards?page=${page}&limit=${limit}&${params}`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchJobcardsUnpaginated = async ({ page, limit, params }: any) => {
  const uri = `jobcards/unpaginated/all?${params}`;
  const response = fetchData({ uri });
  return response;
};

const handleSearchJobcardByWip = async ({ wip }: any) => {
  const uri = `jobcards/search?wip=${wip}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateJobcard = async (data: any) => {
  const uri = `jobcards`;
  const response = postData({ data, uri });
  return response;
};

const handleFetchJobcard = async ({ _id }: any) => {
  const uri = `jobcards/${_id}`;
  const response = fetchData({ uri });
  return response;
};

const handleUpdateJobcard = async ({ data, _id }: any) => {
  const uri = `jobcards/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteJobcard = async ({ _id }: any) => {
  const uri = `jobcards/${_id}`;
  const response = deleteData({ uri });
  return response;
};

const handleUploadJobcardDoc = async ({ data, _id }: any) => {
  const uri = `jobcards/${_id}/documents`;
  const response = putData({ data, uri });
  return response;
};

const handleAssignTechnician = async ({ _id, itemId, data }: any) => {
  const uri = `jobcards/${_id}/items/${itemId}`;
  const response = patchData({ data, uri });
  return response;
};

const handleFetchAvailability = async () => {
  const uri = `lines/technicians/availability/in-progress`;
  const response = fetchData({ uri });
  return response;
};

const handleJobcardsDataByStatus = async ({ status }: any) => {
  const uri = `jobcards/status?status=${status}`;
  const response = fetchData({ uri });
  return response;
};

const handleJobcardsCountByStatus = async ({
  status,
  station,
  dates,
  isCarriedOver,
}: any) => {
  let uri = `jobcards/status/count?status=${status}&station=${station?._id}&startDate=${dates?.startDate}&endDate=${dates?.endDate}`;

  if (isCarriedOver && isCarriedOver === true) {
    uri = `jobcards/status/count?status=${status}&station=${station?._id}&startDate=${dates?.startDate}&endDate=${dates?.endDate}&isCarriedOver=${isCarriedOver}`;
  }
  const response = fetchData({ uri });
  return response;
};

const handleCompletedJobcards = async () => {
  const uri = `jobcards/status/completed`;
  const response = fetchData({ uri });
  return response;
};

const handleAwaitingJobcards = async () => {
  const uri = `jobcards/status/awaiting`;
  const response = fetchData({ uri });
  return response;
};

const handlePausedJobcards = async () => {
  const uri = `jobcards/status/paused`;
  const response = fetchData({ uri });
  return response;
};

const handleInprogressJobcards = async () => {
  const uri = `jobcards/status/inprogress`;
  const response = fetchData({ uri });
  return response;
};

// ITEM

const handleAddJobcardItem = async ({ _id, data }: any) => {
  const uri = `jobcards/${_id}/items`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateJobcardItem = async ({ _id, itemId, data }: any) => {
  const uri = `jobcards/${_id}/items/${itemId}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteJobcardItem = async ({ _id, itemId }: any) => {
  const uri = `jobcards/${_id}/items/${itemId}`;
  const response = deleteData({ uri });
  return response;
};

// TASK

const handleAddJobcardItemTask = async ({ _id, itemId, data }: any) => {
  const uri = `jobcards/${_id}/items/${itemId}/tasks`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateJobcardItemTask = async ({
  _id,
  itemId,
  taskId,
  data,
}: any) => {
  const uri = `jobcards/${_id}/items/${itemId}/tasks/${taskId}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteJobcardItemTask = async ({ _id, itemId, taskId }: any) => {
  const uri = `jobcards/${_id}/items/${itemId}/tasks/${taskId}`;
  const response = deleteData({ uri });
  return response;
};

const handleUpdateJobcardStation = async ({ _id, data }: any) => {
  const uri = `jobcards/${_id}/update/station`;
  const response = putData({ data, uri });
  return response;
};

const handleUpdateJobcardStatus = async ({ _id, data }: any) => {
  const uri = `jobcards/${_id}/update/status`;
  const response = putData({ data, uri });
  return response;
};

const handleMakeJobcardClosed = async ({ _id, data }: any) => {
  const uri = `jobcards/${_id}/update/closed`;
  const response = putData({ data, uri });
  return response;
};

const handleFetchJobcardsByStation = async ({ station }: any) => {
  const uri = `jobcards/all/stations?station=${station}`;
  const response = fetchData({ uri });
  return response;
};

const handleSearchVMSVehicle = async ({ regno }: any) => {
  const uri = `vehicles/registration/${regno}`;
  const response = fetchTrace({ uri });
  return response;
};

const handleStartJobcardItemTask = async ({ taskId, data }: any) => {
  const uri = `tasks/${taskId}/start`;

  const response = patchData({ data, uri });
  return response;
};

const handlePauseJobcardItemTask = async ({ taskId, data }: any) => {
  const uri = `tasks/${taskId}/pause`;

  const response = patchData({ data, uri });
  return response;
};

const handleEODJobcardItemTask = async ({ taskId, data }: any) => {
  const uri = `tasks/${taskId}/eod`;

  const response = patchData({ data, uri });
  return response;
};

const handleCloseJobcardItemTask = async ({ taskId, data }: any) => {
  const uri = `tasks/${taskId}/close`;

  const response = patchData({ data, uri });
  return response;
};

const handleReOpenJobcardItemTask = async ({ taskId, data }: any) => {
  const uri = `tasks/${taskId}/reopen`;

  const response = patchData({ data, uri });
  return response;
};

export {
  handleFetchJobcards,
  handleFetchJobcard,
  handleCreateJobcard,
  handleUpdateJobcard,
  handleDeleteJobcard,
  handleAddJobcardItem,
  handleSearchVMSVehicle,
  handleFetchAvailability,
  handleUpdateJobcardItem,
  handleDeleteJobcardItem,
  handleUploadJobcardDoc,
  handleAssignTechnician,
  handleSearchJobcardByWip,
  handleAddJobcardItemTask,
  handleJobcardsDataByStatus,
  handleJobcardsCountByStatus,
  handleCompletedJobcards,
  handleAwaitingJobcards,
  handlePausedJobcards,
  handleInprogressJobcards,
  handleUpdateJobcardItemTask,
  handleDeleteJobcardItemTask,
  handleUpdateJobcardStation,
  handleFetchJobcardsByStation,
  handleStartJobcardItemTask,
  handlePauseJobcardItemTask,
  handleEODJobcardItemTask,
  handleCloseJobcardItemTask,
  handleReOpenJobcardItemTask,
  handleUpdateJobcardStatus,
  handleMakeJobcardClosed,
  handleFetchJobcardsUnpaginated,
};
