import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, message, Select } from "antd";
import { handleEODJobcardItemTask } from "../../../../api/jobcards";

import { useEffect, useState } from "react";
import { handleFetchReasons } from "../../../../api/reasons";

const { Option } = Select;

export default function AddEOD({
  isVisible,
  setVisible,
  id,
  item,
  task,
}: any) {
  const [reason, setReason] = useState<any>({});
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate: pauseJobcardTask, isPending } = useMutation({
    mutationFn: handleEODJobcardItemTask,
    onSuccess: () => {
      message.success("EODed Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", id] });
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handlePauseJobcardTask = async ({ taskId, data }: any) => {
    await pauseJobcardTask({ _id: id, taskId, data });
  };

  const { data: reasons } = useQuery({
    queryKey: ["reasons"],
    queryFn: handleFetchReasons,
  });

  const handleSelectedReason = (e: any) => {
    const filtered = reasons?.data?.find((reason: any) => reason?._id === e);
    setReason(filtered);
  };

  const onSubmit = async () => {
    const data = {
      reason: reason?.description,
      reasonRef: reason?._id,
      isEOD: reason?.isEOD,
    };
    await handlePauseJobcardTask({
      itemId: item?._id,
      taskId: task?._id,
      data,
    });
    setVisible(false);
  };

  const reasonOptions = reasons?.data?.map((reason: any, index: number) => (
    <Option value={reason?._id} key={index}>
      {reason?.description}
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {}, [task, item]);

  return (
    <Modal
      open={isVisible}
      title="EOD"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="eodForPause"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Pause
        </Button>,
      ]}
    >
      <Form
        id="eodForPause"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="reason"
              label={"Reason"}
              rules={[
                {
                  required: true,
                  message: "Please select reason",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(e) => handleSelectedReason(e)}
              >
                {reasonOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
