import { useEffect } from "react";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";

export default function Inprogress({ jobcards, handleSelectedStatus }: any) {
  const filtered = jobcards?.data?.filter(
    (jobcard: any) => jobcard?.status === "in-progress"
  );

  useEffect(() => {}, [jobcards]);

  return (
    <button
      onClick={() => handleSelectedStatus({ status: "in-progress", jobcards: filtered })}
      className="col-span-12 sm:col-span-3 flex items-center p-2 hover:bg-red-200"
    >
      <div
        className=""
        style={{
          backgroundColor: "#3B82F6",
          width: 4,
          height: 15,
          borderRadius: 5,
        }}
      ></div>
      <div className="ml-2">In-Progress -</div>
      <div className="font-bold px-2  flex justify-center items-center">
        {filtered?.length || 0}
      </div>
      <div className="">
        <HiOutlineChevronDoubleRight />
      </div>
    </button>
  );
}
