import { Routes, Route, useLocation, Navigate } from "react-router-dom";

/*
|---------------------------------------------------------------------
|                             Main
|---------------------------------------------------------------------
*/

import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/redirects/NotFound";
import Signin from "./pages/auth/Signin";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Denied from "./pages/auth/Denied";
import AuthLayout from "./layouts/AuthLayout";
import Dashboard from "./pages/dashboards/Dashboard";
import Manage from "./pages/manage/Manage";
import RTSCodes from "./pages/manage/rtscodes/RTSCodes";
import Stations from "./pages/manage/stations/Stations";
import Technicians from "./pages/manage/technicians/Technicians";
import Users from "./pages/manage/users/Users";
import Roles from "./pages/manage/roles/Roles";
import Jobcards from "./pages/manage/jobcards/Jobcards";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Jobcard from "./pages/manage/jobcards/Jobcard";
import Workshops from "./pages/jobcards/Station";
import Models from "./pages/manage/models/Models";
import MyProfile from "./pages/profile/MyProfile";
import Skills from "./pages/manage/skills/Skills";
import Generics from "./pages/manage/generics/Generics";
import React from "react";
import Reasons from "./pages/manage/reasons/Reasons";
import Teams from "./pages/manage/teams/Teams";
import Attendance from "./pages/manage/attendance/Attendance";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Signin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/denied" element={<Denied />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/station"
              element={
                <ProtectedRoute>
                  <Workshops />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />

            {/* MANAGE */}
            <Route
              path="/manage"
              element={
                <ProtectedRoute>
                  <Manage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/jobcards"
              element={
                <ProtectedRoute>
                  <Jobcards />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/jobcards/:id"
              element={
                <ProtectedRoute>
                  <Jobcard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/manage/rtscodes"
              element={
                <ProtectedRoute>
                  <RTSCodes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/models"
              element={
                <ProtectedRoute>
                  <Models />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/stations"
              element={
                <ProtectedRoute>
                  <Stations />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/technicians"
              element={
                <ProtectedRoute>
                  <Technicians />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/users"
              element={
                // <ProtectedRoute>
                <Users />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/manage/roles"
              element={
                <ProtectedRoute>
                  <Roles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/skills"
              element={
                <ProtectedRoute>
                  <Skills />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/generics"
              element={
                <ProtectedRoute>
                  <Generics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/reasons"
              element={
                <ProtectedRoute>
                  <Reasons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/teams"
              element={
                <ProtectedRoute>
                  <Teams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/manage/attendance"
              element={
                <ProtectedRoute>
                  <Attendance />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { token } = React.useContext(AuthContext);
  let location = useLocation();
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export default App;
