import getTimeHrs from "./getTimeHrs";

const getItemHrs = (tasks: any) => {
  let hrs: any = 0;
  tasks?.forEach((task: any) => {
    task?.actions?.forEach((action: any) => {
      if(!action?.isPause){
        hrs += getTimeHrs(action.startTime, action.endTime);
      }
    });
  });
  return parseFloat(hrs).toFixed(1);
};

export default getItemHrs;
