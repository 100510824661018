import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import putData from "../services/putData";
import uploadRTS from "../services/uploadRTS";


const handleFetchRTSCodes = async () => {
  const uri = `rts`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateRTSCode = async (data: any) => {
  const uri = `rts`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateRTSCode = async ({ _id, data }: any) => {
  const uri = `rts/${_id}`;
  const response = putData({ data, uri });
  return response;
};


const handleDeleteRTSCode = async ({_id,data}: any) => {
  const uri = `rts/${_id}`;
  const response = deleteData({ data, uri });
  return response;
};


const handleUploadRTSCode = async ({data}: any) => {
  const uri = `rts/upload`;
  const response = uploadRTS({ data, uri });
  return response;
};

const handleCreateRTSTask = async ({_id, data}: any) => {
  const uri = `rts/${_id}/tasks`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateRTSTask = async ({_id, taskId, data}: any) => {
  const uri = `rts/${_id}/tasks/${taskId}`;
  const response = patchData({ data, uri });
  return response;
};


const handleDeleteRTSTask = async ({_id, taskId, data}: any) => {
  const uri = `rts/${_id}/tasks/${taskId}`;
  const response = deleteData({ data, uri });
  return response;
};


const handleDeleteZeros = async ({_id, data}: any) => {
  const uri = `rts/${_id}/tasks/delete/zeros`;
  const response = deleteData({ data, uri });
  return response;
};



export {
  handleFetchRTSCodes,
  handleCreateRTSCode,
  handleUpdateRTSCode,
  handleDeleteRTSCode,
  handleCreateRTSTask,
  handleUpdateRTSTask,
  handleDeleteRTSTask,
  handleUploadRTSCode,
  handleDeleteZeros
};
