import BackNav from "../../../components/toolbars/BackNav";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useState } from "react";
import CreateCode from "./components/CreateCode";
import UpdateCode from "./components/UpdateCode";
import DeleteCode from "./components/DeleteCode";
import Something from "../../../components/shared/Something";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleDeleteZeros, handleFetchRTSCodes } from "../../../api/rtscodes";
import { Button, Collapse, Table, message } from "antd";
import CreateTask from "./components/CreateTask";
import UpdateTask from "./components/UpdateTask";
import DeleteTask from "./components/DeleteTask";
import { LuUpload } from "react-icons/lu";
import UploadRTS from "./components/UploadRTS";

export default function RTSCodes() {
  const [createItemModal, setCreateItemModal] = useState<boolean>(false);
  const [updateItemModal, setUpdateItemModal] = useState<boolean>(false);
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);
  const [createTaskModal, setCreateTaskModal] = useState<boolean>(false);
  const [updateTaskModal, setUpdateTaskModal] = useState<boolean>(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState<boolean>(false);
  const [uploadRTSModal, setUploadRTSModal] = useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<any>({});
  const [selectedCode, setSelectedCode] = useState<any>({});
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: rtscodes,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["rtscodes"],
    queryFn: handleFetchRTSCodes,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteZeros,
    onSuccess: () => {
      message.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: ["rtscodes"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  function groupBy(array: any, nestedProperty: any) {
    const resultArray: any[] = [];
    const groupsMap = new Map();

    array?.forEach((obj: any) => {
      const keys = nestedProperty?.split(".");
      let value = obj;
      for (const key of keys) {
        value = value[key];
      }

      if (!groupsMap.has(value)) {
        groupsMap.set(value, []);
      }
      groupsMap.get(value).push(obj);
    });

    groupsMap.forEach((groupValue, key) => {
      resultArray.push({ code: key, items: groupValue });
    });

    return resultArray;
  }

  const model = groupBy(rtscodes?.data, "model.name");

  const onChange = (params: any, code: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: rtscodes?.data?.length,
      code,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <div className="text-gray-700 ml-4">
          {pagination?.code === record?.model?.name ? (
            <div className="">
              {pagination?.page * pagination?.per_page -
                pagination?.per_page +
                index +
                1}
            </div>
          ) : (
            <div className="">{index + 1}</div>
          )}
        </div>
      ),
    },
    {
      title: "CODE",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">{record?.code || "-"}</span>
      ),
    },
    {
      title: "DESCRIPTION",
      width: 200,
      render: (record: any) => (
        <div className="">
          <Collapse
            bordered={false}
            size="small"
            ghost
            items={[
              {
                key: 1,
                label: (
                  <div className="flex justify-between">
                    <div className="text-gray-700 font-bold">
                      {record?.description || "-"}
                    </div>
                    <div className="">{record?.duration}</div>
                  </div>
                ),
                children: (
                  <div className="bg-white">
                    <div className="flex justify-between items-end border-b bg-gray-200 p-1">
                      <div className="font-bold mb-1 text-md">Tasks</div>
                      <div className="">
                        <Button
                          onClick={() => {
                            setSelectedCode({ ...record });
                            setCreateTaskModal(true);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    <div className="flex justify-between bg-gray-100">
                      <div className="flex">
                        <div className="w-8">S/N</div>
                        <div className="flex-1">Name</div>
                      </div>
                      <div className="text-right">Expected Hrs</div>
                    </div>
                    {record?.tasks?.map((item: any, index: number) => (
                      <div className="flex justify-between">
                        <div className="flex">
                          <div className="w-8">{index + 1}.</div>
                          <div className="flex-1">{item?.description}</div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-right">
                            {item?.expectedHours}
                          </div>
                          <button
                            onClick={() => {
                              setSelectedTask({
                                ...item,
                              });
                              setSelectedCode({
                                ...record,
                              });
                              setUpdateTaskModal(true);
                            }}
                            className="flex justify-center items-center p-1 text-yellow-500  hover:bg-yellow-200 cursor-pointer"
                          >
                            <FiEdit />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedTask({
                                ...item,
                              });
                              setSelectedCode({
                                ...record,
                              });
                              setDeleteTaskModal(true);
                            }}
                            className="flex justify-center items-center p-1 text-red-500   hover:bg-red-200 cursor-pointer"
                          >
                            <AiOutlineDelete />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              },
            ]}
          ></Collapse>
        </div>
      ),
    },
    {
      title: "MODEL",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">{record?.model?.name || "-"}</span>
      ),
    },
    {
      title: "DURATION",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">{record?.duration || "-"}</span>
      ),
    },
    {
      title: "TYPE",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">{record?.codetype || "-"}</span>
      ),
    },
    {
      title: "ACTION",
      width: 80,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="flex">
            <button
              onClick={() => {
                setSelectedCode({
                  ...record,
                });
                setUpdateItemModal(true);
              }}
              className="flex justify-center items-center mx-1 text-gray-700 border rounded-md px-2 py-1 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
            >
              <FiEdit />
            </button>
            <button
              onClick={() => {
                setSelectedCode({
                  ...record,
                });
                setDeleteItemModal(true);
              }}
              className="flex justify-center items-center text-gray-700 border rounded-md px-2 py-1 bg-red-100 hover:bg-red-200 cursor-pointer"
            >
              <AiOutlineDelete />
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">RTS Codes</div>
            <div className="text-gray-600">Manage RTS Codes</div>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => setUploadRTSModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
            >
              <LuUpload /> <span className="mx-2">Upload</span>
            </button>
            <button
              onClick={() => setCreateItemModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>

        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="m-2">
              {model?.map((item: any, index: any) => (
                <Collapse
                  bordered={false}
                  size="small"
                  ghost
                  items={[
                    {
                      key: 1,
                      label: (
                        <div className="flex justify-between">
                          <div className="text-gray-700 font-bold uppercase">
                            {item?.code || "-"}
                          </div>
                          <div className="flex items-center">
                            <div className="">RTS CODES :</div>
                            <div className="font-bold w-20 border-l-2 flex justify-center items-center mx-2 bg-gray-100">
                              {item?.items?.length}{" "}
                            </div>
                          </div>
                        </div>
                      ),
                      children: (
                        <div className="mb-2" key={index}>
                          <div className="">
                            <Table
                              columns={columns}
                              dataSource={item?.items}
                              size="small"
                              bordered
                              pagination={{ pageSize: 10 }}
                              scroll={{ y: 625 }}
                              loading={false}
                              rowKey="id"
                              onChange={(e) => onChange(e, item.code)}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                ></Collapse>
              ))}
            </div>
          </div>
        )}
      </div>

      <CreateCode
        isVisible={createItemModal}
        setVisible={setCreateItemModal}
        refetch={refetch}
      />

      <UpdateCode
        isVisible={updateItemModal}
        setVisible={setUpdateItemModal}
        selected={selectedCode}
      />

      <DeleteCode
        isVisible={deleteItemModal}
        setVisible={setDeleteItemModal}
        selected={selectedCode}
      />

      <CreateTask
        isVisible={createTaskModal}
        setVisible={setCreateTaskModal}
        selected={selectedCode}
      />

      <UpdateTask
        isVisible={updateTaskModal}
        setVisible={setUpdateTaskModal}
        selectedTask={selectedTask}
        selectedCode={selectedCode}
      />

      <DeleteTask
        isVisible={deleteTaskModal}
        setVisible={setDeleteTaskModal}
        selectedTask={selectedTask}
        selectedCode={selectedCode}
      />

      <UploadRTS isVisible={uploadRTSModal} setVisible={setUploadRTSModal} />
    </div>
  );
}
