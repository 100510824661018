import getTimeHrs from "./getTimeHrs";

const getTaskHrsHuman = (actions: any) => {
  let hrs: any = 0;
  actions?.forEach((item: any) => {
    if(!item?.isPause){
      hrs += getTimeHrs(item.startTime, item.endTime);
    }
  });
  let res = parseFloat(hrs)


  const hours = Math.floor(res);

  const minutesDecimal = (res - hours) * 60;
  const minutes = Math.floor(minutesDecimal);

  const seconds = Math.round((minutesDecimal - minutes) * 60);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export default getTaskHrsHuman;
