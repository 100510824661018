import getPercent from "../../../utils/getPercent";

export default function Timer({estimate, actual}: any) {
  const DIAMETER = 60;
  const STROKE_WIDTH = 6;
  const RADIUS = DIAMETER / 2 - STROKE_WIDTH / 2;

  const CIRCUMFERENCE = Math.PI * RADIUS * 2;

  const perceUsage = () => {
    const amount = actual / estimate;
    return amount;
  };

  const position = Math.max(1 - perceUsage(), 0);
  // const percentage = (perceUsage() * 100).toFixed(1);
  const percentage : any= getPercent(actual, estimate)

  return (
    <div className="flex justify-center items-center text-black">
    <svg
      viewBox="0 0 80 80"
      width="160px"
      height="160px"
      className="circle-progress z-0"
    >
      <circle
        cx={DIAMETER / 2}
        cy={DIAMETER / 2}
        r={RADIUS}
        stroke={ percentage > 100 ? "#E50000": "#4F774E"}
        fill="transparent"
        strokeWidth={STROKE_WIDTH}
        style={{
          strokeDasharray: CIRCUMFERENCE,
          strokeDashoffset: CIRCUMFERENCE * position,
        }}
      />
    </svg>
    <div
      style={{
        backgroundColor: "#B7A9B2",
        width: "120px",
        height: "120px",
      }}
      className="w-28 h-28 rounded-full flex justify-center items-center -ml-40 -mt-10"
    >
      <div className="w-24 h-24 flex-col rounded-full bg-white flex justify-center items-center">
        <div className="text-xs">Time Spent</div>
        <div className="font-bold text-2xl">
          {percentage}
          <span className="text-xs">%</span>
        </div>
      </div>
    </div>
  </div>
  );
}
