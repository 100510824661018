import { HiOutlineChevronDoubleRight } from "react-icons/hi";

export default function Completed({ count }: any) {
  return (
    <button
      className="col-span-12 sm:col-span-3 flex items-center p-2 hover:bg-red-200 cursor-not-allowed"
    >
      <div
        className=""
        style={{
          backgroundColor: "#30A34A",
          width: 4,
          height: 15,
          borderRadius: 5,
        }}
      ></div>
      <div className="ml-2">Completed -</div>
      <div className="font-bold px-2  flex justify-center items-center">
        {count || 0}
      </div>
      <div className="">
        <HiOutlineChevronDoubleRight />
      </div>
    </button>
  );
}
