import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import { handleUpdateModel } from "../../../../api/models";

const { Option } = Select;

export default function UpdateRole({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateModel,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["models"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ name: selected?.name });
    form.setFieldsValue({ code: selected?.code });
    form.setFieldsValue({ body: selected?.body });
    form.setFieldsValue({ description: selected?.description });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Model"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateModel"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateModel"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="code"
              label="Code"
              initialValue={selected?.code}
              rules={[
                {
                  required: true,
                  message: "Please enter code",
                },
              ]}
            >
              <Input placeholder="Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="body"
              label="Body Type"
              rules={[
                {
                  required: true,
                  message: "Please enter body type",
                },
              ]}
            >
              <Select placeholder="Body Type">
                <Option value="sedan">Sedan</Option>
                <Option value="suv">SUV</Option>
                <Option value="hatchback">Hatchback</Option>
                <Option value="coupe">Coupe</Option>
                <Option value="pickup">Pickup</Option>
                <Option value="minivan">Minivan</Option>
                <Option value="convertible">Convertible</Option>
                <Option value="truck">Truck</Option>
                <Option value="van">Van</Option>
                <Option value="wagon">Wagon</Option>
                <Option value="crossover suv">Crossover SUV</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              initialValue={selected?.description}
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
