import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScorePercent = ({ score }: any) => {
    let pathColor : any = "#f44336"
    if (score > 25 && score < 100) {
        pathColor = "#eab308";
      } else if (score === 100) {
        pathColor = "#16a34a";
      }
  return (
    <div style={{ width: '60px', height: '60px' }}>
      <CircularProgressbar
        value={score}
        text={`${score}%`}

        circleRatio={0.5}
        styles={buildStyles({
            rotation: 3 / 4,
            strokeLinecap: "butt",
            pathColor: pathColor,
            trailColor: "#d6d6d6"
          })}
      />
    </div>
  );
};

export default ScorePercent;