import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import fetchTrace from "../services/fetchTrace";
import patchData from "../services/patchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchUsers = async () => {
  const uri = `users`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateUser = async (data: any) => {
  const uri = `users`;
  const response = postData({ data, uri });
  return response;
};

const handleFetchAuthUsers = async () => {
  const uri = `users`;
  const response = fetchTrace({ uri });
  return response;
};


const handleUpdateUser = async ({ _id, data }: any) => {
  const uri = `users/${_id}`;
  const response = putData({ data, uri });
  return response;
};


const handleDeleteUser = async (data: any) => {
  const uri = `users`;
  const response = deleteData({ data, uri });
  return response;
};


const handleUpdateSeller = async ({ _id, data }: any) => {
  const uri = `users/${_id}/seller`;
  const response = patchData({ data, uri });
  return response;
};

export {
  handleFetchUsers,
  handleCreateUser,
  handleFetchAuthUsers,
  handleUpdateUser,
  handleDeleteUser,
  handleUpdateSeller
};
