import { Button, Drawer, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import avatar from "../../../assets/images/technician.jpg";
import getTaskHrs from "../../../utils/getTaskHrs";
import getPercent from "../../../utils/getPercent";
import getEfficiency from "../../../utils/getEfficiency";
import TaskHumanTime from "../../manage/jobcards/components/TaskHumanTime";
import getTaskPausedHrs from "../../../utils/getTaskPausedHrs";
import TaskPausedHumanTime from "../../manage/jobcards/components/TaskPausedHumanTime";
import JobTime from "../../jobcards/components/JobTime";
import { bgColors } from "../../../utils/getColors";

export default function JobcardPanel({ isVisible, setVisible, jobcard }: any) {
  function groupItemsByTechnician(items: any) {
    const grouped = items?.reduce((acc: any, item: any) => {
      const technicianId = item?.technician?._id;
      if (!acc[technicianId]) {
        acc[technicianId] = {
          name: item?.technician?.name,
          avatar: item?.technician?.avatar,
          items: [],
        };
      }
      acc[technicianId]?.items.push(item);
      return acc;
    }, {});

    return Object.keys(grouped).map((technicianId) => ({
      technicianId,
      name: grouped[technicianId]?.name,
      avatar: grouped[technicianId]?.avatar,
      items: grouped[technicianId]?.items,
    }));
  }

  const groupedItems = groupItemsByTechnician(jobcard?.items || []);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg uppercase">
            JOBCARD : WIP {jobcard?.wip}
          </span>
        </div>
      }
      placement={"right"}
      width={1200}
      onClose={() => setVisible(false)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(false)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="border-b-2 pb-2 -mt-4">
          <div className="text-sky-600 font-bold text-4xl">
            <span className="text-gray-600">CUSTOMER : </span>
            {jobcard?.customer}
          </div>
          <div className="flex">
            <div className="uppercase">
              <span className="font-bold">Vehicle: </span>
              {jobcard?.registration}
            </div>
            <div className="mx-2 uppercase">
              {" "}
              <span className="font-bold">Station :</span>{" "}
              {jobcard?.station?.name}
            </div>
          </div>
        </div>

        <div className="mt-4">
          {groupedItems?.map((item: any, index: number) => (
            <div key={index}>
              <div className="flex">
                <div className="">
                  <img
                    src={item?.avatar || avatar}
                    style={{
                      width: 130,
                      backgroundColor: "#ECECEC",
                      borderRadius: 10,
                    }}
                    className="w-32 h-40 aspect-[4/4] border object-cover"
                  />

                  <div className="font-bold uppercase">{item?.name}</div>
                </div>
                <div className="flex-1">
                  {item?.items?.map((itm: any, index: number) => (
                    <div className="mx-2 mb-4" key={index}>
                      <div className="flex justify-between">
                        <div className="text-2xl truncate font-bold">
                          TASK {index + 1} : {itm?.rtscode} - {itm?.description}
                        </div>
                      </div>
                      <div className="">
                        {itm?.tasks?.map((task: any, index: number) => (
                          <div
                            className="mx-4 flex justify-between mb-1"
                            key={index}
                          >
                            <div className="font-semibold flex justify-between">
                              <div className="">
                                Sub-Task {index + 1} :{task?.description}
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div
                                className="uppercase"
                                style={{ color: bgColors[task?.status] }}
                              >
                                {task?.status}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="">
          <div className="flex items-center text-md">
            <div className="">
              <JobTime
                items={jobcard?.items}
                expected={jobcard?.expectedHours}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
