import { Button, Modal, Form, Row, Col, Select, Input, message } from "antd";
import { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchRoles } from "../../../../api/roles";
import { handleCreateUser, handleFetchAuthUsers } from "../../../../api/users";
import getBase64 from "../../../../utils/getBase64";

const { Option } = Select;

export default function CreateUser({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: handleFetchRoles,
  });

  const { data: users } = useQuery({
    queryKey: ["auth_users"],
    queryFn: handleFetchAuthUsers,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleCreateUser,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const { userId, role, accessLevel } = await form.getFieldsValue();
    const user = users?.payload?.filter((item: any) => item?._id === userId);
    const data = {
      name: `${user[0]?.firstname} ${user[0]?.lastname}`,
      email: user[0]?.email,
      mobile: user[0]?.mobile,
      role,
      accessLevel,
      signature: base64,
    };
    await mutate(data);
    setVisible(false);
  };

  const userOptions = users?.payload?.map((user: any, index: number) => (
    <Option value={user?._id} key={index}>
      {user?.firstname + " " + user?.lastname}
    </Option>
  ));

  const roleOptions = roles?.data?.map((role: any, index: number) => (
    <Option value={role?._id} key={index}>
      <span className="capitalize">{role?.name}</span>
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  return (
    <Modal
      open={isVisible}
      title="New User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="userId"
              label="Select User"
              rules={[
                {
                  required: true,
                  message: "Please select user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and select user"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="role"
              label={"Designation / Role"}
              rules={[
                {
                  required: true,
                  message: "Please select designation",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="accessLevel"
              label={"Access Level"}
              rules={[
                {
                  required: true,
                  message: "Please select access level",
                },
              ]}
            >
              <Select placeholder="Access Level">
                <Option value={1}>Level 1</Option>
                <Option value={2}>Level 2</Option>
                <Option value={3}>Level 3</Option>
                <Option value={4}>Level 4</Option>
                <Option value={9}>Level 9</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Signature ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Signature"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
