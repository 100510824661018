import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, message, Select } from "antd";
import { handleFetchRTSCodes } from "../../../../api/rtscodes";
import { handleFetchTechnicians } from "../../../../api/technicians";
import { handleAddJobcardItem } from "../../../../api/jobcards";
import { MdCheck } from "react-icons/md";

import { useState } from "react";

const { Option } = Select;

export default function AddLineItem({ isVisible, setVisible, _id }: any) {
  const [assign, setAssign] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: rtscodes } = useQuery({
    queryKey: ["rtscodes"],
    queryFn: handleFetchRTSCodes,
  });

  const { data: technicians } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleAddJobcardItem,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { rts, technician } = await form.getFieldsValue();
    const data = {
      rts,
      technician,
    };
    await mutate({ _id, data });
    setVisible(false);
  };

  const rtscodeOptions = rtscodes?.data?.map((code: any, index: number) => (
    <Option value={code?._id} key={index}>
      {`${code?.code} : ${code?.description}`}
    </Option>
  ));

  const technicianOptions = technicians?.data?.map(
    (technician: any, index: number) => (
      <Option value={technician?._id} key={index}>
        {technician?.name}
      </Option>
    )
  );

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Add Line Item"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="addLineItem"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="addLineItem"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="rts"
              label={"RTS Code"}
              rules={[
                {
                  required: true,
                  message: "Please select rts code",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select Title"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {rtscodeOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => setAssign(!assign)}
            className={`w-6 h-6 border flex justify-center items-center ${
              assign ? "bg-green-100" : ""
            }`}
          >
            {assign ? <MdCheck size={20} /> : null}
          </button>
          <div className="mx-2">Assign Technician Now ( Optional )</div>
        </div>
        <Row gutter={[16, 0]}>
          {assign && (
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="technician"
                label={"Technician"}
                rules={[
                  {
                    required: false,
                    message: "Please select technician",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search and Select Technician"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {technicianOptions}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
}
