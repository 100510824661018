import { useQuery } from "@tanstack/react-query";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchTeams } from "../../../api/teams";
import Something from "../../../components/shared/Something";
import { useState } from "react";
import CreateTeam from "./components/CreateTeam";
import AssignMember from "./components/AssignMember";
import { RiDeleteBin4Line, RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";

import { CiSquareRemove } from "react-icons/ci";

import { LuSearch } from "react-icons/lu";

export default function Teams() {
  const [createTeamModal, setCreateTeamModal] = useState<boolean>(false);
  const [updateTeamModal, setUpdateTeamModal] = useState<boolean>(false);
  const [deleteTeamModal, setDeleteTeamModal] = useState<boolean>(false);
  const [assignMemberModal, setAssignMemberModal] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<any>({});

  const {
    data: teams,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: handleFetchTeams,
  });

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Teams</div>
            <div className="text-gray-600">Manage Technician Teams</div>
          </div>

          <div className="flex">
            <button
              onClick={() => setCreateTeamModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>

        <div className="">
          {isError ? (
            <Something refetch={refetch} isFetching={isFetching} />
          ) : (
            <div className="m-2 pb-8">
              <div className="grid grid-cols-12 gap-2">
                {teams?.data?.map((team: any, index: number) => (
                  <div
                    className="col-span-12 sm:col-span-12 mb-4 border p-4"
                    key={index}
                  >
                    <div className="flex justify-between items-center border-b-2 pb-2">
                      <div className="text-4xl font-bold">
                        {index + 1}. {team?.name}
                      </div>
                      <button
                        onClick={() => {
                          setSelectedTeam(team);
                          setAssignMemberModal(true);
                        }}
                        className="border py-1 px-4"
                      >
                        + Assign Members
                      </button>
                    </div>

                    {team?.technicians?.length === 0 ? (
                      <div className="flex flex-col items-center justify-center text-gray-600 mt-4">
                        <LuSearch />
                        <div className="text-sm">No Members Added</div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="">Members</div>
                        <div className="grid grid-cols-12 gap-2">
                          {team?.technicians?.map(
                            (technician: any, index: number) => (
                              <div className={`col-span-12 sm:col-span-3 border-l-2 pl-2 ${technician?.status === "available" ? "border-green-500": "border-yellow-500"}`}>
                                <div
                                  className="flex justify-between mb-1"
                                  key={index}
                                >
                                  <div className="flex">
                                    <div className="">
                                      <img
                                        src={technician?.avatar}
                                        alt=""
                                        style={{
                                          width: 60,
                                        }}
                                         className="w-full aspect-[4/5] object-cover"
                                      />
                                    </div>
                                    <div className="flex flex-col mx-2">
                                      <div className="">{technician?.name}</div>
                                      <div className="text-gray-600">
                                        Mobile : {technician?.mobile || ""}
                                      </div>
                                      <div className="text-gray-60 text-xs">
                                        Status : <span className={`capitalize ${technician?.status === "available" ? "text-green-600": ""}`}>{technician?.status || ""}</span>
                                      </div>

                                  
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                        <div className="">
                                          <FiEdit />
                                        </div>
                                        <div className="mx-1">
                                          <RiDeleteBin4Line size={18}/>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <CreateTeam isVisible={createTeamModal} setVisible={setCreateTeamModal} />
      <AssignMember
        isVisible={assignMemberModal}
        setVisible={setAssignMemberModal}
        team={selectedTeam}
      />
    </div>
  );
}
