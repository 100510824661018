import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { GoChevronRight } from "react-icons/go";
import Moment from "react-moment";
import {
  handleCreateAttendance,
  handleFetchAttendance,
} from "../../../api/attendance";
import { useContext, useEffect, useState } from "react";
import TakeAttendance from "./TakeAttendance";
import { FiEdit } from "react-icons/fi";
import NotifyToday from "./NotifyToday";
import { LuCalendarPlus } from "react-icons/lu";
import { message } from "antd";
import AttendanceDetails from "./AttendanceDetails";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Attendance() {
  const { auth } = useContext(AuthContext);
  const today = moment(new Date()).format("YYYYMMDD");
  const td = new Date();
  const [takeModal, setTakeModal] = useState<boolean>(false);
  const [notifyModal, setNotifyModal] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [technicians, setTechnicians] = useState<any>([]);
  const [title, setTitle] = useState<any>("");
  const [date, setDate] = useState<any>(today);
  const queryClient = useQueryClient();

  const { data: attendance, isLoading } = useQuery({
    queryKey: ["attendances", date],
    queryFn: () => handleFetchAttendance({ date }),
  });

  const getAttendance = (attendance: any) => {
    let absentData = [];
    let presentData = [];
    let total = 0;
    let all = 0;
    let absentCount = 0;
    let presentCount = 0;
    if (attendance?.data === null) {
    } else {
      absentData = attendance?.data?.technicians?.filter(
        (item: any) => item?.isPresent === false
      );
      presentData = attendance?.data?.technicians?.filter(
        (item: any) => item?.isPresent === true
      );
      absentCount = absentData?.length;
      presentCount = presentData?.length;
      total = presentCount + absentCount;
      all = attendance?.data?.technicians?.length;
    }
    return {
      total,
      all,
      absentData,
      presentData,
      absentCount,
      presentCount,
    };
  };

  const { all, total, absentCount, presentCount, absentData, presentData } =
    getAttendance(attendance);

  const { mutate } = useMutation({
    mutationFn: handleCreateAttendance,
    onSuccess: () => {
      message.success("Created Successfully");
      queryClient.invalidateQueries({ queryKey: ["attendances"] });
      setTakeModal(true);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const data = {
      date: td,
      fdate: Number(moment(new Date(td)).format("YYYYMMDD")),
      year: new Date(td).getFullYear(),
      month: new Date(td).getMonth() + 1,
      day: new Date(td).getDate(),
    };

    await mutate(data);
  };

  useEffect(() => {
    if (auth?.takeAttendance) {
      if (!isLoading && attendance?.data === null) {
        // Trigger the function again if data.response is null
        handleFetchAttendance({ date });
        setNotifyModal(true);
      }
    }
  }, [today]);

  return (
    <div className="flex flex-col">
      <div className="border-b py-3 px-4 flex justify-between">
        <div className="">ATTENDANCY</div>
        <div className="uppercase">
          {/* <Moment format="Do MMM YYYY">{today}</Moment> */}
          <button className="ml-2" onClick={() => setTakeModal(true)}>
            <FiEdit />
          </button>
        </div>
      </div>
      <div className="">
        {all == 0 ? (
          <div className="">
            {auth?.takeAttendance ? (
              <div className="flex  justify-center items-center py-8">
                <button
                  onClick={() => onSubmit()}
                  className="flex items-center bg-green-100 px-4 py-1 rounded-lg border hover:bg-green-200"
                >
                  <div className="mr-2">
                    <LuCalendarPlus size={30} />
                  </div>
                  <div className="flex flex-col items-start ">
                    <div className="">Take Attendance</div>
                    <div className="text-xs text-gray-500">
                      <Moment format="Do MMM YYYY">{today}</Moment>
                    </div>
                  </div>
                </button>
              </div>
            ):
            
            <div className="flex  justify-center items-center py-8">No Attendance</div>
            }
          </div>
        ) : (
          <div className="">
            <button
              onClick={() => {
                setTechnicians(presentData);
                setTitle("Present");
                setDetailModal(true);
              }}
              className="flex justify-between items-center p-4 bg-green-100"
            >
              <div className="text-6xl px-2 w-32 bg-green-200 text-center text-green-600">
                {presentCount || "-"}
              </div>
              <div className="">
                <div className="">Present</div>
                <div className="text-xs flex items-center">
                  View All <GoChevronRight />
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setTechnicians(absentData);
                setTitle("Absent");
                setDetailModal(true);
              }}
              className="flex justify-between items-center p-4 bg-red-100"
            >
              <div className="text-6xl px-2 w-32 bg-red-200 text-center text-red-600">
                {absentCount || "-"}
              </div>
              <div className="">
                <div className="">Absent</div>
                <div className="text-xs flex items-center">
                  View All <GoChevronRight />
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setTechnicians(attendance?.data?.technicians);
                setTitle("All");
                setDetailModal(true);
              }}
              className="flex justify-between items-center p-4 bg-blue-100"
            >
              <div className="text-6xl px-2 w-32 bg-blue-200 text-center text-blue-600">
                {total}
              </div>
              <div className="">
                <div className="">Total</div>
                <div className="text-xs flex items-center">
                  View All <GoChevronRight />
                </div>
              </div>
            </button>
          </div>
        )}
      </div>
      <TakeAttendance
        attendance={attendance}
        isLoading={isLoading}
        date={date}
        isVisible={takeModal}
        setVisible={setTakeModal}
      />
      <NotifyToday
        isVisible={notifyModal}
        setVisible={setNotifyModal}
        handleCreate={onSubmit}
        today={today}
      />
      <AttendanceDetails
        isVisible={detailModal}
        setVisible={setDetailModal}
        technicians={technicians}
        title={title}
      />
    </div>
  );
}
