import { Modal } from "antd";
import { LuCalendarPlus } from "react-icons/lu";
import Moment from "react-moment";

export default function NotifyToday({
  isVisible,
  setVisible,
  handleCreate,
  today,
}: any) {
  return (
    <Modal
      open={isVisible}
      title="Create Attendance"
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="flex  justify-center items-center py-8">
        <button
          onClick={() => {
            handleCreate();
            setVisible(false);
          }}
          className="flex items-center bg-green-100 px-4 py-1 rounded-lg border hover:bg-green-200"
        >
          <div className="mr-2">
            <LuCalendarPlus size={30} />
          </div>
          <div className="flex flex-col items-start ">
            <div className="">Take Attendance</div>
            <div className="text-xs text-gray-500">
              <Moment format="Do MMM YYYY">{today}</Moment>
            </div>
          </div>
        </button>
      </div>
    </Modal>
  );
}
