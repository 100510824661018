import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function LargeItem({ menu }: any) {
  const { name, aicon, icon, path } = menu;

  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: false });
  return (
    <div
      className={`flex justify-center items-center w-28 bg-toyota  ${
        match
          ? "border-t-4 border-white font-bold"
          : "border-t-4 border-toyota"
      }`}
    >
      <Link to={path}>
        <div className={`${match ? "rounded-2xl": "rounded-2xl"} text-white mx-2 flex flex-col justify-center items-center`}>
          <div
            className={`uppercase ${
              match ? "" : ""
            }`}
          >
            {name}
          </div>
        </div>
      </Link>
    </div>
  );
}