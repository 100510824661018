import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, message, Select } from "antd";
import { handleFetchTechnicians } from "../../../../api/technicians";
import { handleAssignTeamMember } from "../../../../api/teams";
import { useEffect } from "react";
const { Option } = Select;

export default function AssignMember({ isVisible, setVisible, team }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleAssignTeamMember,
    onSuccess: () => {
      message.success("Assigned Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: technicians } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const technicianOptions = technicians?.data
  ?.filter((item: any) => item?.team?._id !== team?._id)
  .map((technician: any, index: number) => (
    <Option value={technician?._id} key={index}>
      {technician?.name}
    </Option>
  ));


  const onSubmit = async () => {
    const { technicianId } = await form.getFieldsValue();
    const data = {
      technicianId,
    };
    await mutate({ _id: team?._id, data });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };
  useEffect(() => {

  },[team])

  return (
    <Modal
      open={isVisible}
      title="Assign Member"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="assignMember"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="assignMember"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="technicianId"
              label={"Member"}
              rules={[
                {
                  required: true,
                  message: "Please select technician",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Technician"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {technicianOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
