import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal, Form, message, Row, Col, Button, DatePicker } from "antd";

import { AiOutlineCheck } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import {
  handleUpdateTechnicianLeave,
  handleUpdateTechnicianStatus,
} from "../../../../api/technicians";
import TextArea from "antd/es/input/TextArea";

export default function UpdateStatus({
  isVisible,
  setVisible,
  technician,
}: any) {
  const [selected, setSelected] = useState<any>(technician?.status);
  const queryClient = useQueryClient();

  const [leave, setLeave] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { mutate: updteStatus } = useMutation({
    mutationFn: handleUpdateTechnicianStatus,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["technicians"] });
    },
    onError: (error) => {
      if (error.toString() !== "status must be a string") {
        message.error(`${error}`);
      }
    },
  });

  const { mutate: updateLeave } = useMutation({
    mutationFn: handleUpdateTechnicianLeave,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["technicians"] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async (status: any) => {
    const data = {
      status,
    };
    if (status !== "") {
      await updteStatus({
        _id: technician?._id,
        data: { isActive: true, ...data },
      });
      setVisible(false);
    }
  };

  const onSubmitLeave = async () => {
    const values = await form.validateFields();
    if (values.status !== "") {
      await updateLeave({
        _id: technician?._id,
        data: { isActive: false, status: "absent", ...values },
      });
      setVisible(false);
    }
  };

  const status = [
    { id: 1, name: "Available", value: "available", isSupported: true },
    { id: 2, name: "On Duty", value: "on duty", isSupported: true },
  ];

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    setSelected(technician?.status);
  }, [technician]);

  return (
    <Modal
      open={isVisible}
      title="Technician Status"
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        id="updateTechnicianStatus"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        {!leave ? (
          <div className="">
            {status?.map((item: any, index: number) => (
              <button
                onClick={() => {
                  onSubmit(item?.value);
                  setSelected(item?.value);
                }}
                className={`flex items-center mb-2 bg-gray-100 w-full p-2  ${
                  item?.isSupported ? "" : "text-gray-200"
                }`}
                key={index}
              >
                <div className="w-8 h-8 border flex justify-center items-center">
                  {selected === item?.value ? <AiOutlineCheck /> : ""}
                </div>
                <div className="ml-2">{item?.name}</div>
              </button>
            ))}

            <button
              onClick={() => setLeave(true)}
              type="button"
              className={`flex items-center mb-2 bg-gray-100 w-full p-2`}
            >
              <div className="w-8 h-8 border flex justify-center items-center"></div>
              <div className="ml-2">Absent / Leave</div>
            </button>
          </div>
        ) : (
          <div className="">
            <button
              type="button"
              onClick={() => setLeave(false)}
              className="flex items-center pt-4 pb-2"
            >
              <BsChevronLeft />
              <div className="ml-1">Back</div>
            </button>

            <div className="text-red-600">Status: Absent</div>
            <Form
              id="updateTechnicianLeave"
              form={form}
              name="normal_login"
              className="login-form"
              onFinish={onSubmit}
              layout="vertical"
            >
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    name="leaveStart"
                    label="Leave Start Date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter leave start date",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      placeholder="Leave Start"
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    name="leaveEnd"
                    label="Leave End Date"
                    rules={[
                      {
                        required: true,
                        message: "Please enter leave end date",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      placeholder="Leave End"
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="leaveReason"
                    label="Reason of Absence"
                    initialValue={technician?.leaveReason}
                    rules={[
                      {
                        required: true,
                        message: "Please enter leave reason",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Reason of Absence"
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  danger
                  type="primary"
                  htmlType="button"
                  onClick={() => onSubmitLeave()}
                >
                  Submit
                </Button>
                <Button
                  danger
                  htmlType="button"
                  className="mx-2"
                  onClick={clearForm}
                >
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Form>
    </Modal>
  );
}
