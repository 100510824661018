import { FiList } from "react-icons/fi";
import { LuCalendarDays } from "react-icons/lu";
import Moment from "react-moment";
import Completed from "../../pages/jobcards/components/Completed";
import CarryOvers from "../../pages/jobcards/components/CarryOvers";
import Inprogress from "../../pages/jobcards/components/Inprogress";
import Paused from "../../pages/jobcards/components/Paused";
import Awaiting from "../../pages/jobcards/components/Awaiting";
import { useQuery } from "@tanstack/react-query";
import { handleJobcardsCountByStatus } from "../../api/jobcards";
import { useState } from "react";
import JobcardDetails from "../../pages/dashboards/components/JobcardDetails";
import JobcardFiltered from "../../pages/dashboards/components/JobcardFiltered";

export default function StationHeader({ dates, station, jobcards }: any) {
  const today = new Date();
  const [detailsPanel, setDetailsPanel] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedJobcards, setSelectedJobcards] = useState<any>("");

  const handleSelectedStatus = ({ status, jobcards }: any) => {
    setSelectedStatus(status);
    setSelectedJobcards(jobcards);
    setDetailsPanel(true);
  };

  const { data: count } = useQuery({
    queryKey: ["stationCount", "completed", station?._id],
    queryFn: () =>
      handleJobcardsCountByStatus({ status: "completed", station, dates }),
    select: (data: any) => data?.payload || 0,
  });

  return (
    <div className="flex justify-between items-center border-b border-gray-300 py-1 px-4 bg-red-100">
      <div className=" border-gray-300">
        <div className="grid grid-cols-6">
          <div className="col-span-12 md:col-span-6">
            <div className="flex items-center justify-center sm:justify-start">
              <div className="bg-gray-100 h-8 rounded-xl w-20 flex justify-between items-center">
                <div className="h-7 w-10 bg-white mx-1 flex justify-center items-center rounded-xl">
                  <FiList size={20} color="#323232" />
                </div>
                <div className="h-7 w-10 bg-gray-100 mx-1 flex justify-center items-center rounded-xl">
                  <LuCalendarDays size={20} color="#323232" />
                </div>
              </div>

              <div className="flex border-l">
                <span className="font-bold mx-2 uppercase">
                  <Moment format="MMM DD, YYYY">{today}</Moment>
                </span>
                <span className="text-gray-500">TODAY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-1 ">
        <div className="grid grid-cols-12 gap-x-2">
          <Completed
            count={count}
            handleSelectedStatus={handleSelectedStatus}
          />
          <Inprogress
            station={station}
            dates={dates}
            jobcards={jobcards}
            handleSelectedStatus={handleSelectedStatus}
          />
          <Paused
            station={station}
            dates={dates}
            jobcards={jobcards}
            handleSelectedStatus={handleSelectedStatus}
          />
          <Awaiting
            station={station}
            dates={dates}
            jobcards={jobcards}
            handleSelectedStatus={handleSelectedStatus}
          />
        </div>

        <div className="grid grid-cols-12 gap-x-2">
          <CarryOvers
            count={count}
            jobcards={jobcards}
            handleSelectedStatus={handleSelectedStatus}
          />
        </div>
      </div>

      <JobcardFiltered
        isVisible={detailsPanel}
        setVisible={setDetailsPanel}
        selectedStatus={selectedStatus}
        selectedJobcards={selectedJobcards}
        station={station}
        dates={dates}
      />
    </div>
  );
}
