import { useQuery } from "@tanstack/react-query";
import { handleFetchJobcardsUnpaginated } from "../../../api/jobcards";
import { useEffect, useState } from "react";
import { Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { FaRegChartBar } from "react-icons/fa";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
export default function Reasons({ station, dates }: any) {
  const [data, setData] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<any>(0);

  const params = `station=${station?._id}&startDate=${dates?.startDate}&endDate=${dates?.endDate}`;

  const { data: jobcards } = useQuery<any, Error>({
    queryKey: ["reasonsData", params],
    queryFn: () => handleFetchJobcardsUnpaginated({ params }),
    enabled: !!station,
    select: (data: any) => data?.payload || [],
  });

  const prepareDate = () => {
    if (!jobcards) return;
    const reasonMap: { [key: string]: any } = {};
    let actions: any = [];
    for (const jobcard of jobcards) {
      actions = jobcard?.items?.flatMap((item: any) =>
        item.tasks.flatMap((task: any) => task.actions)
      );
    }

    for (const action of actions) {
      if (action.isPause) {
        const name = action?.name || action?.action;
        const startTime: any = new Date(action?.startTime);
        const endTime: any = action?.endTime
          ? new Date(action.endTime)
          : new Date();
        const duration = (endTime - startTime) / 1000 / 60 / 60;
        const roundedDuration = Number(duration.toFixed(2));

        if (!reasonMap[name]) {
          reasonMap[name] = {
            name: name,
            value: 0,
          };
        }

        reasonMap[name].value += roundedDuration;
      }
    }
    let ans = Object.values(reasonMap);
    setData(ans);
  };

  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    prepareDate();
  }, [station, dates]);

  return (
    <div className="" style={{ height: "300px" }}>
      <div className="border-b py-3 px-4">
        <div className="">STOPPAGE REASONS</div>
      </div>

      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#FACC16"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <FaRegChartBar size={40} color="#808080" />
          <div className="text-gray-500">Data Here</div>
        </div>
      )}
    </div>
  );
}
