import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchAttendances = async () => {
  const uri = `attendances`;
  const response = fetchData({ uri });
  return response;
};
const handleFetchAttendance = async ({date}: any) => {
  const uri = `attendances/today?date=${date}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateAttendance = async (data: any) => {
  const uri = `attendances`;
  const response = postData({ data, uri });
  return response;
};

const handleFillAttendance = async ({data, _id, technicianId}: any) => {
  const uri = `attendances/${_id}/fill/${technicianId}`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateAttandance = async ({ data, _id }: any) => {
  const uri = `attendances/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteAttandance = async ({ _id }: any) => {
  const uri = `attendances/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchAttendances,
  handleFetchAttendance,
  handleFillAttendance,
  handleCreateAttendance,
  handleUpdateAttandance,
  handleDeleteAttandance,
};
