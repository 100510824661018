import getHumanTime from "../../../utils/getHumanTime";
import getItemHrs from "../../../utils/getItemHrs";
import getItmExpHrs from "../../../utils/getItmExpHrs";

export default function ItemTime({ tasks, expected }: any) {
  let time: any = 0;
  if (expected) {
    time = getItmExpHrs(tasks);
  } else {
    time = getItemHrs(tasks);
  }

  const { hours, minutes } = getHumanTime(time);
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="flex font-bold">
          {expected ? (
            <div className="w-24">Allowed Time </div>
          ) : (
            <div className="w-24">Time Taken</div>
          )}
          : {time}
        </div>
      </div>
      <div className="flex items-center">
        {hours > 0 && <span className="mr-1">{hours} Hrs </span>}{" "}
        {minutes > 0 && <span className="">{minutes} Mins </span>}
      </div>
    </div>
  );
}
