import {
  Button,
  Drawer,
  Form,
  Space,
  Input,
  Tooltip,
  Popover,
  message,
  Table,
} from "antd";
import Moment from "react-moment";
import moment from "moment";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";
import JobcardPanel from "./JobcardPanel";
import JobTime from "../../jobcards/components/JobTime";

const { Search } = Input;

export default function JobcardFiltered({
  isVisible,
  setVisible,
  selectedStatus,
  selectedJobcards,
  station,
  dates,
}: any) {
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [jobcardModal, setJobcardModal] = useState<boolean>(false);
  const [selectedJobcard, setSelectedJobcard] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [mailForm] = Form.useForm();

  const initials = {
    wip: "",
    reg: "",
  };

  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  });

  const handlePageChange = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      page: e.current,
      limit: e.pageSize,
    }));
  };

  const groupedTech = (items: any) => {
    const uniqueTechnicians: any = [];
    const technicianIds = new Set();

    items.forEach((item: any): any => {
      const technician = item?.technician;
      if (!technicianIds.has(technician?._id)) {
        technicianIds.add(technician?._id);
        uniqueTechnicians.push(technician);
      }
    });

    return uniqueTechnicians;
  };

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.limit - pagination?.limit + index + 1}
        </span>
      ),
    },
    {
      title: "DESCRIPTION",
      width: 120,
      render: (record: any) => (
        <div className="">
          <div className="text-gray-700 font-bold truncate">
            {record?.description || "Jobcard"} - WIP NO: {record?.wip || "-"}
          </div>
          <JobTime items={record?.items} expected={record?.expectedHours} />
        </div>
      ),
    },
    {
      title: "CUSTOMER",
      width: 90,
      render: (record: any) => (
        <div className="text-gray-700">
          <div className="">
            <span className="font-bold">{record?.customer}</span>
          </div>
          <div className="">
            <span>Vehicle </span>
            <span>: {record?.registration}</span>
          </div>
        </div>
      ),
    },
    {
      title: "TECHNICIAN(S)",
      width: 60,
      render: (record: any) => (
        <div className="">
          {groupedTech(record?.items)?.map((technician: any) => (
            <div className="flex">
              <div className="mr-2">
                {technician?.avatar && (
                  <img
                    src={technician?.avatar}
                    style={{
                      width: 40,
                      backgroundColor: "#ECECEC",
                      borderRadius: 10,
                    }}
                    className="w-12 h-12 aspect-[4/4] border object-cover"
                  />
                )}
              </div>
              {technician?.name ? (
                <span>{technician?.name}</span>
              ) : (
                <span className="text-red-500">Not Assigned</span>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "STATUS",
      width: 60,
      render: (record: any) => (
        <div className="">
          <div className="text-gray-700">
            <div className="">
              <span>Station: {record?.station?.name}</span>
            </div>
          </div>
          <div className="text-black">
            Status: <span className="capitalize">{record?.status}</span>
          </div>
        </div>
      ),
    },
    {
      title: "CREATED",
      width: 60,
      render: (record: any) => (
        <div className="">
          <div className="text-gray-700">
            <div className="">
              Created:{" "}
              <Moment format="Do MMM, YYYY">{record?.createdAt}</Moment>
            </div>
            <div className="">
              <div className="flex text-black">
                State:
                {record?.isClosed ? (
                  <div className="text-red-500 mx-2">Closed</div>
                ) : (
                  <div className="text-green-500 mx-2">Open</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    if (selectedJobcards?.length === 0) {
      message.error("No Data Found");
      return;
    }
    setMailLoading(true);
    const dt = selectedJobcards?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Description: item?.description || "-",
        "WIP No.": item?.wip || "-",
        "Customer Name": item?.customer || "-",

        "Start Date": item?.datein ? (
          <Moment format="Do MMM YYYY">{item?.datein}</Moment>
        ) : (
          "-"
        ),

        "End Date": item?.dateout ? (
          <Moment format="Do MMM YYYY">{item?.dateout}</Moment>
        ) : (
          "-"
        ),
        Station: item?.station?.name || "-",
        "Created at": moment(item?.createdAt).format("DD/MM/YYYY"),
        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(dt);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    //   await postDataMail(maildata, mailuri);
    setMailLoading(false);

    message.success("Email Sent Successfully");
    mailForm.resetFields();
    toggleMessageModal(false);
  };

  const clearAllFilters = async () => {
    form.resetFields();
    setFilters({ ...initials });
  };

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg uppercase">
            {selectedStatus} JOBS :
          </span>
          {dates?.isRange ? (
            <div className="text-gray-500 uppercase">
              <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
              <span className="px-2">-</span>
              <Moment format="Do MMM, YYYY">{dates?.endDate}</Moment>
            </div>
          ) : (
            <div className="text-gray-500 uppercase">
              <Moment format="Do MMM, YYYY">{dates?.startDate}</Moment>
            </div>
          )}
        </div>
      }
      placement={"right"}
      width={1200}
      onClose={() => setVisible(false)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(false)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4 bg-white">
          <div className="flex">
            <div className="">
              <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
                <Form layout="inline" form={form}>
                  <Form.Item name="wip">
                    <Search
                      placeholder="Search by WIP No."
                      allowClear
                      onSearch={(e: any) => {
                        setFilters({ ...filters, wip: e });
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="reg">
                    <Search
                      placeholder="Search by Vehicle Reg."
                      allowClear
                      onSearch={(e: any) => {
                        setFilters({ ...filters, reg: e });
                      }}
                    />
                  </Form.Item>
                  <Button
                    className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
                    onClick={() => {
                      clearAllFilters();
                    }}
                  >
                    <RiFilterOffLine color="gray" />
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    form={mailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" danger htmlType="submit">
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="bottom"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={20} />
                </button>
              </Tooltip>
            </Popover>
            <button
              className="border bg-white px-4 ml-2 py-1"
              onClick={() =>
                ExportToExcel(
                  selectedJobcards?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Description: item?.description || "-",
                      "WIP No.": item?.wip || "-",
                      "Customer Name": item?.customer || "-",

                      "Start Date": item?.datein ? (
                        <Moment format="Do MMM YYYY">{item?.datein}</Moment>
                      ) : (
                        "-"
                      ),

                      "End Date": item?.dateout ? (
                        <Moment format="Do MMM YYYY">{item?.dateout}</Moment>
                      ) : (
                        "-"
                      ),
                      Station: item?.station?.name || "-",
                      "Created at": moment(item?.createdAt).format(
                        "DD/MM/YYYY"
                      ),
                      Status: item?.status === null ? "-" : item?.status,
                    };
                  }),
                  "Jobcards.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </div>
        </div>

        <div className="">
          <Table
            columns={columns}
            dataSource={selectedJobcards}
            size="small"
            bordered
            scroll={{ y: 625 }}
            rowKey="id"
            onRow={(record: any) => {
              return {
                onClick: () => {
                  setSelectedJobcard(record);
                  setJobcardModal(true);
                },
              };
            }}
            pagination={{
              current: pagination?.page,
              pageSize: pagination?.limit,
              total: pagination?.total,
            }}
            onChange={(e: any) => handlePageChange(e)}
            rowClassName={"hover:cursor-pointer hover:bg-red-50"}
          />
        </div>
      </div>

      <JobcardPanel
        isVisible={jobcardModal}
        setVisible={setJobcardModal}
        jobcard={selectedJobcard || []}
      />
    </Drawer>
  );
}
