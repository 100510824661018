import getTimeHrsHuman from '../../../../utils/getTimeHrsHuman'

export default function ActionHumanTime({startTime, endTime}: any) {
    const {hours, minutes, seconds} = getTimeHrsHuman(startTime, endTime)
  return (
    <div className="flex justify-end item-center">
    {hours > 0 && <div className="mr-1">{hours} Hrs </div>}
    {minutes > 0 && <div className="">{minutes} Mins </div>}
    {hours < 0 && minutes > 0 && <div className=''>{seconds} Secs</div>}
  </div>
  )
}
