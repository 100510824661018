import SmallMenu from "../../components/menus/SmallMenu";
import LargeMenu from "../../components/menus/LargeMenu";
import logo from "../../assets/images/logo_white.png";
import { Link } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { Popover } from "antd";
import { useQuery } from "@tanstack/react-query";
import { handleFetchStations } from "../../api/stations";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { FaRegUser } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
export default function Header() {
  const { updateStation, station, auth } = useContext(AuthContext);

  const { data: stations } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  const handleSelectStation = (station: any) => {
    if (station === 0) {
      updateStation({ _id: "", name: "All Stations", branch: "" });
    } else {
      const selected = stations?.data.find((s: any) => s?._id === station);
      updateStation(selected);
    }
  };

  const workshops = (
    <div>
      {stations?.data?.map((item: any, index: number) => (
        <button
          onClick={() => handleSelectStation(item?._id)}
          key={index}
          className="border-t py-1 hover:bg-gray-100 cursor-pointer w-full flex justify-between"
        >
          <div className="">{item?.name}</div>
          <div
            className={`w-6 h-6 flex justify-center items-center border border-gray-400 ${
              item?._id === station?._id
                ? "bg-green-500"
                : station?._id === ""
                ? "bg-green-500"
                : ""
            }`}
          >
            {item?._id === station?._id ? (
              <MdCheck color="#FFFFFF" />
            ) : station?._id === "" ? (
              <MdCheck color="#FFFFFF" />
            ) : null}
          </div>
        </button>
      ))}

      <button
        onClick={() => handleSelectStation(0)}
        className="border-t-4 py-1 hover:bg-gray-100 cursor-pointer w-full flex justify-between"
      >
        <div className="">All Stations</div>
        <div
          className={`w-6 h-6 flex justify-center items-center border border-gray-400 ${
            station?._id === "" ? "bg-green-500" : ""
          }`}
        >
          {station?._id === "" ? <MdCheck color="#FFFFFF" /> : null}
        </div>
      </button>
    </div>
  );

  useEffect(() => {}, [station]);
  return (
    <div className="">
      <div className="h-16 bg-toyota flex justify-between items-center">
        <div className="grid grid-cols-12 w-full">
          <div className="col-span-4 flex items-center mx-4 font-bold text-3xl">
            <div className="pr-2">
              <img src={logo} alt="Logo" height={20} width={50} />
            </div>
            <div className="">
              <Popover
                placement="bottom"
                title={"Select Station"}
                content={workshops}
              >
                <div className="flex items-center cursor-pointer">
                  <div className="text-white pl-2 hidden md:block border-l uppercase">
                    {station ? (
                      <div className="">{station?.name}</div>
                    ) : (
                      <div className="">Station</div>
                    )}
                  </div>
                  <div className="">
                    <GoChevronDown color="#FFFFFF" size={34} />
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="col-span-4 flex items-center justify-center">
            <div className="hidden sm:block">
              <LargeMenu />
            </div>
          </div>
          <div className="col-span-4  flex px-4 justify-end items-center">
            <div className="flex justify-end">
              <div className="flex mx-2">
                <div className=" flex-col justify-center items-end hidden md:block">
                  <div className="text-white">{auth?.name}</div>
                  <div className="text-xs text-gray-100 capitalize">
                    {auth?.role?.name}
                  </div>
                </div>
              </div>
              <Link
                to="/profile"
                className="h-12 w-12 border-2 rounded-full flex justify-center items-center"
              >
                <FaRegUser color={"#FFFFFF"} size={24} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center bg-white border-b">
        <div className="sm:hidden">
          <SmallMenu />
        </div>
      </div>
    </div>
  );
}
