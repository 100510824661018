import getJobHrs from "../../../utils/getJobHrs";
import getHumanTime from "../../../utils/getHumanTime";
import getJobExpHrs from "../../../utils/getJobExpHrs";

export default function JobTime({ items }: any) {
  const expected = getJobExpHrs(items);
  const { hours: exHrs, minutes: exMins } = getHumanTime(expected);

  const actual = getJobHrs(items);
  const { hours: acHrs, minutes: acMins } = getHumanTime(actual);

  return (
    <div>
      <div className="flex items-center text-gray-600">
        <div className="w-24 mr-1">Allowed Time: </div>
        <div className="w-8 text-center">{expected}</div>:- {exHrs} Hrs {exMins} Mins
      </div>
      <div className="flex items-center text-gray-600">
        <div className="w-24">Time Taken: </div>
        <div className="w-8 text-center">{actual}</div>:- {acHrs} Hrs {acMins} Mins
      </div>
    </div>
  );
}
