import { Drawer } from "antd";
import { BsDownload, BsEye, BsEyeFill, BsUpload } from "react-icons/bs";
import pht_avatar from "../../../../assets/images/photo.png";
import pdf_avatar from "../../../../assets/images/pdf.png";

import { useState } from "react";
import UploadDocument from "./UploadDocument";
import formatSize from "../../../../utils/formatSize";

export default function Documents({
  isVisible,
  setVisible,
  jobcard,
  _id,
}: any) {
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [itemFileModal, setItemFileModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [preview, setPreview] = useState<any>("");
  return (
    <Drawer
      open={isVisible}
      title={
        <div className="flex justify-between items-start">
          <div className="flex flex-col">
            <div className="font-bold text-4xl">DOCUMENTS</div>

            <div className="flex items-center">
              <span className="mr-2 text-lg">
                <span className="font-bold">WIP : </span>
              </span>
              <div className="flex text-gray-500 px-2 uppercase">
                {jobcard?.wip}
              </div>
            </div>
          </div>
          <button
            onClick={() => setUploadModal(true)}
            className="flex items-center bg-gray-300 py-2 px-4 hover:bg-blue-400 hover:text-white"
          >
            <BsUpload />
            <span className="mx-2">UPLOAD</span>
          </button>
        </div>
      }
      placement="right"
      width={700}
      onClose={() => setVisible(!isVisible)}
      closable={false}
    >
      <div className="">
        {jobcard?.documents?.map((document: any, index: number) => (
          <div
            className="flex justify-between mt-2 border-b pb-2 mb-2"
            key={index}
          >
            <div className="flex">
              <div className="mr-2">
                <img
                  src={
                    document.filetype === "application/pdf"
                      ? pdf_avatar
                      : pht_avatar
                  }
                  alt="Logo"
                  height={15}
                  width={35}
                />
              </div>
              <div className="flex flex-col justify-center">
                <div className="">{document?.description}</div>
                <div className="flex items-center text-xs">
                  <span className="w-16">{formatSize(document?.filesize)}</span>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              {document?.filetype === "application/pdf" ? (
                <a
                  href={`${document?.url}`}
                  target="_blank"
                  className="ml-4 hover:text-green-600 text-black"
                >
                  <BsDownload />
                </a>
              ) : (
                <>
                  {preview?._id === document?._id ? (
                    <button onClick={() => setPreview("")} className="">
                      <BsEyeFill color="#16a34a" />
                    </button>
                  ) : (
                    <button onClick={() => setPreview(document)} className="">
                      <BsEye />
                    </button>
                  )}
                  <a
                    href={document?.url}
                    target="_blank"
                    download={true}
                    className="ml-2 text-black"
                  >
                    <button>
                      <BsDownload />
                    </button>
                  </a>
                </>
              )}
            </div>
          </div>
        ))}

        {preview ? (
          <div className="border p-1 mt-4">
            {preview?.filetype === "application/pdf" ? (
              <div className="">
                <a href={`${preview?.url}`} target="_black">
                  Pdf
                </a>
              </div>
            ) : (
              <img src={`${preview?.url}`} />
            )}
          </div>
        ) : null}
      </div>

      <UploadDocument
        isVisible={uploadModal}
        setVisible={setUploadModal}
        jobcard={jobcard}
        _id={_id}
      />
    </Drawer>
  );
}
