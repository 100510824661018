import { Button, Drawer, Modal, Space } from "antd";
import { AiOutlineClose } from "react-icons/ai";

export default function AttendanceDetails({
  isVisible,
  setVisible,
  technicians,
  title,
}: any) {
  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg uppercase">ATTENDANCE: {title}</span>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(false)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(false)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      {technicians?.map((technician: any, index: number) => (
        <div className="flex items-center mb-2 pb-2 border-b">
          <div className="">
            <img
              src={technician?.avatar}
              alt=""
              style={{
                width: 40,
              }}
              className="w-full aspect-[4/4] object-contain"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-black">{technician?.name}</div>
            <div
              className={`text-gray-600 text-xs ${
                technician?.isPresent ? "text-green-600" : "text-red-600"
              }`}
            >
              {technician?.isPresent ? "Present" : "Absent"}
            </div>
          </div>
        </div>
      ))}
    </Drawer>
  );
}
