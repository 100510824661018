import {  Table } from "antd";
import BackNav from "../../../components/toolbars/BackNav";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useState } from "react";
import CreateSkill from "./components/CreateSkill";
import UpdateSkill from "./components/UpdateSkill";
import DeleteSkill from "./components/DeleteSkill";
import Something from "../../../components/shared/Something";
import { useQuery } from "@tanstack/react-query";
import { handleFetchSkills } from "../../../api/skills";

export default function Skills() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const {
    data: skills,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["skills"],
    queryFn: handleFetchSkills,
  });

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "CODE",
      width: 10,
      render: (record: any) => (
        <span className="text-gray-700 capitalize">{record?.code || "-"}</span>
      ),
    },
    {
      title: "DESCRIPTION",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.description}
        </span>
      ),
    },
    {
      title: "SHORTNAME",
      width: 60,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.shortname}
        </span>
      ),
    },
    {
      title: "ACTION",
      width: 50,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="flex m-2">
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setUpdateModal(true);
              }}
              className="flex justify-center items-center mx-1 text-gray-700 border rounded-md px-2 py-1 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
            >
              <FiEdit />
            </button>
            <button
              onClick={() => {
                setSelected({
                  ...record,
                });
                setDeleteModal(true);
              }}
              className="flex justify-center items-center text-gray-700 border rounded-md px-2 py-1 bg-red-100 hover:bg-red-200 cursor-pointer"
            >
              <AiOutlineDelete />
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Skills</div>
            <div className="text-gray-600">Manage User Skills</div>
          </div>
          <div className="">
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>

        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <Table
              columns={columns}
              dataSource={skills?.data}
              size="small"
              bordered
              pagination={{ pageSize: 20 }}
              scroll={{ y: 525 }}
              loading={isLoading}
              rowKey="id"
            />
          </div>
        )}
      </div>

      <CreateSkill
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={refetch}
      />

      <UpdateSkill
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
      />

      <DeleteSkill
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={selected}
      />
    </div>
  );
}
