import fetchData from "../services/fetchData";


const handleFetchAvailability = async () => {
  const uri = `lines/technicians/availability`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchOnDuty = async () => {
  const uri = `lines/technicians/onduty`;
  const response = fetchData({ uri });
  return response;
};

export {
  handleFetchAvailability,
  handleFetchOnDuty
};
