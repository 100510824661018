import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";
import {
  handleSearchVMSVehicle,
  handleUpdateJobcard,
} from "../../../../api/jobcards";
import { handleFetchStations } from "../../../../api/stations";
import { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";

const { Option } = Select;

export default function UpdateJobcard({
  isVisible,
  setVisible,
  _id,
  jobcard,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [reg, setReg] = useState<any>(jobcard?.registration);
  const [err, setErr] = useState<any>(null);

  const { data: stations } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateJobcard,
    onSuccess: (res) => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
      setVisible(false);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const { mutate: searchVehicle, isPending: searching } = useMutation({
    mutationFn: handleSearchVMSVehicle,
    onSuccess: (res) => {
      if (res?.payload?.length > 0) {
        const data = res.payload[0];

        form.setFieldsValue({
          customer: data?.contact?.customer?.name,
          registration: data?.registration,
        });
      } else {
        message.warning("Vehicle not found, Verify or Fill the form Manually");
      }
      setErr(false);
    },
    onError: (error) => {
      message.warning(`${error}, Fill the form Manually`);
    },
  });

  const handleSearchVehicle = async () => {
    if (!reg) {
      setErr(true);
      return;
    }
    form.resetFields();
    const formated = reg.toUpperCase().replace(/\s/g, "");
    await searchVehicle({ regno: formated });
  };

  const onSubmit = async () => {
    const {
      wip,
      registration,
      customer,
      datein,
      dateout,
      description,
      station,
    } = await form.getFieldsValue();
    const data = {
      wip: Number(wip),
      registration,
      customer,
      datein,
      dateout,
      description,
      station,
    };
    await mutate({ _id, data });
  };

  const stationOptions = stations?.data?.map(
    (technician: any, index: number) => (
      <Option value={technician?._id} key={index}>
        {technician?.name}
      </Option>
    )
  );

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ wip: jobcard?.wip });
    form.setFieldsValue({ station: jobcard?.station?._id });
    form.setFieldsValue({ registration: jobcard?.registration });
    form.setFieldsValue({ customer: jobcard?.customer });
  }, [jobcard]);

  return (
    <Modal
      open={isVisible}
      title="Update Jobcard"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateJobcard"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <div className="flex border-b pb-2 mb-2">
        <div className="w-5/6">
          <input
            type="text"
            placeholder="Search Vehicle Registration. i.e T127BRD"
            className={`border py-1 px-2 rounded-sm flex-1 w-full ${
              err ? "border-red-500" : ""
            }`}
            width={"100%"}
            value={reg}
            onChange={(e) => {
              setErr(false);
              setReg(e.target.value);
            }}
          />
        </div>
        <div className="w-1/6">
          <button
            className="bg-toyota p-2 ml-2"
            onClick={() => handleSearchVehicle()}
          >
            {searching ? (
              <FiLoader color="#FFFFFF" />
            ) : (
              <BsSearch color="#FFFFFF" />
            )}
          </button>
        </div>
      </div>
      <Form
        id="updateJobcard"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="wip"
              label="WIP No."
              rules={[
                {
                  required: true,
                  message: "Please enter wip number",
                },
              ]}
            >
              <Input type="text" placeholder="WIP No." />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="station"
              label={"Station"}
              rules={[
                {
                  required: false,
                  message: "Please select station",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select Station"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {stationOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="registration"
              label="Vehicle Registration No."
              rules={[
                {
                  required: true,
                  message: "Please enter Registration number",
                },
              ]}
            >
              <Input type="text" placeholder="Registration No." />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="customer"
              label="Customer Name"
              rules={[
                {
                  required: true,
                  message: "Please enter customer",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Customer Name"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="datein"
              label="Start Date"
              rules={[
                {
                  required: false,
                  message: "Please enter start date",
                },
              ]}
            >
              <DatePicker
                placeholder="Start Date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="dateout"
              label="End Date"
              rules={[
                {
                  required: false,
                  message: "Please enter end date",
                },
              ]}
            >
              <DatePicker
                placeholder="End Date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
