const getTimeHrsHuman = (startTime: Date, endTime: Date) => {
  const startJsDate = new Date(startTime);
  const endJsDate = new Date(endTime);
  const ms = endJsDate.getTime() - startJsDate.getTime();
  const hrs = (ms / (1000 * 60 * 60)).toFixed(2);
  const parsedHrs = parseFloat(hrs);
  const res =  parsedHrs < 0 ? 0 : parsedHrs;


  const hours = Math.floor(res);

  const minutesDecimal = (res - hours) * 60;
  const minutes = Math.floor(minutesDecimal);

  const seconds = Math.round((minutesDecimal - minutes) * 60);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export default getTimeHrsHuman;
