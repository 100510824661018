function getBudgetHrs(hoursPerMonth: any, range: any) {
    const hoursPerDay = Number((hoursPerMonth / 22).toFixed(1)); // Assuming 22 working days per month
    const hoursPerWeek = Number((hoursPerDay * 5).toFixed(1)); // Assuming 5 working days per week
    const hoursPerYear = Number((hoursPerMonth * 12).toFixed(1)); // 12 months in a year

    switch(range){
        case "day":
            return hoursPerDay;
        case "week":
            return hoursPerWeek;
        case "month":
            return hoursPerMonth;
        case "year":
            return hoursPerYear;
    }
}
 export default getBudgetHrs
