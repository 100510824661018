import Timer from "./components/Timer";
import avatar from "../../assets/images/technician.jpg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  handleFetchJobcardsByStation,
  handleUpdateJobcardItem,
} from "../../api/jobcards";
import Moment from "react-moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import getItemHrs from "../../utils/getItemHrs";
import Details from "./components/Details";
import { pusher } from "../..";
import { RiTimer2Fill, RiUser3Line } from "react-icons/ri";
import getEfficiency from "../../utils/getEfficiency";
import { bgColors, txColors } from "../../utils/getColors";
import { IoChevronDownSharp } from "react-icons/io5";
import UpdateStatus from "../manage/jobcards/components/UpdateStatus";
import JobTime from "./components/JobTime";
import ItemTime from "./components/ItemTime";
import { GoArrowSwitch } from "react-icons/go";
import UpdateStation from "../manage/jobcards/components/UpdateStation";
import { AiOutlineFileExclamation } from "react-icons/ai";

import moment from "moment";
import StationHeader from "../../components/shared/StationHeader";
import { message } from "antd";
import { TbFoldDown, TbFoldUp } from "react-icons/tb";
import IdleTime from "./components/IdleTime";

export default function Station() {
  const { station } = useContext(AuthContext);
  const [activeJobcard, setActiveJobcard] = useState<any>({});
  const [activeItem, setActiveItem] = useState<any>({});
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [detailsModal, setDetailsModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [stationModal, setStationModal] = useState<boolean>(false);

  const [dates, setDates] = useState<any>({
    startDate: moment().format("YYYYMMDD"),
    endDate: moment().format("YYYYMMDD"),
    period: "day",
    isRange: false,
    isAll: false,
  });

  const { data: jobcards, refetch } = useQuery({
    queryKey: ["wips"],
    queryFn: () => handleFetchJobcardsByStation({ station: station?._id }),
  });

  useEffect(() => {
    const work = pusher.subscribe(`jobcards`);
    work.bind("updated", function (data: any) {
      refetch();
    });
    return () => {
      pusher.unsubscribe("jobcards");
    };
  }, []);

  const queryClient = useQueryClient();

  const { mutate: updateExpanded } = useMutation({
    mutationFn: handleUpdateJobcardItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["wips"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleUpdateExpand = async ({ item, isExpanded }: any) => {
    await updateExpanded({
      data: { isExpanded: !isExpanded },
      _id: item?.jobcard,
      itemId: item?._id,
    });
  };

  useEffect(() => {
    refetch();
  }, [station]);

  useEffect(() => {}, [dates, station]);

  return (
    <div className="">
      <StationHeader station={station} dates={dates} jobcards={jobcards} />
      <div className="">
        <div className="flex-grow overflow-x-auto scrollbar-hide">
          <div className="grid grid-cols-12 grid-flow-row-dense  gap-x-1">
            {jobcards?.data?.map((jobcard: any, index: number) => (
              <div
                className="col-span-12 sm:col-span-6 md:col-span-4 bg-white"
                key={index}
              >
                <div className="flex flex-col bg-blue-100 border-b-4 border-gray-300 px-4 py-1">
                  <div className="flex justify-between mb-2">
                    <div>
                      <div className="font-bold text-4xl">
                        WIP {jobcard?.wip}
                      </div>
                      {jobcard?.isCarriedOver ? (
                        <div className="flex items-center text-toyota text-xs">
                          <RiTimer2Fill />
                          <div className="mx-1">Carry Over</div>
                          <div className="">
                            {" "}
                            :{" "}
                            <Moment format="Do MMM, YYYY">
                              {jobcard?.createdAt}
                            </Moment>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center text-green-500 text-xs">
                          <RiTimer2Fill />
                          <div className="mx-1">Today's</div>
                          <div className="">
                            {" "}
                            :{" "}
                            <Moment format="Do MMM, YYYY">
                              {jobcard?.createdAt}
                            </Moment>
                          </div>
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setActiveJobcard(jobcard);
                          setStationModal(true);
                        }}
                        className="flex items-center"
                      >
                        <span className="mx-1">{jobcard?.station?.name}</span>
                        <GoArrowSwitch />
                      </button>
                    </div>
                    <div className="flex flex-col">
                      <button
                        onClick={() => {
                          setActiveJobcard(jobcard);
                          setStatusModal(true);
                        }}
                        className="text-lg uppercase flex items-center justify-between"
                      >
                        <span className="mr-1 font-bold">
                          {jobcard?.status}
                        </span>
                        <IoChevronDownSharp size={22} />
                      </button>
                      <div className="flex items-center justify-end text-xs">
                        <div className="">
                          <JobTime
                            items={jobcard?.items}
                            expected={jobcard?.expectedHours}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between  text-xs">
                    <div className="flex flex-col">
                      <div className="w-48">
                        <span className="font-bold ">Created: </span>
                        <Moment format="Do MMM YYYY - HH:MM">
                          {jobcard?.createdAt}
                        </Moment>
                      </div>
                      <div className="w-48 flex">
                        {jobcard?.startedDate ? (
                          <div className="">
                            <span className="font-bold">Started: </span>
                            {jobcard?.startedDate && (
                              <Moment format="Do MMM YYYY - HH:MM">
                                {jobcard?.startedDate}
                              </Moment>
                            )}
                          </div>
                        ) : (
                          <div className="text-blue-100">Not Started</div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      {jobcard?.startedDate && (
                        <IdleTime
                          start={jobcard?.createdAt}
                          end={jobcard?.startedDate}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex justify-between items-center border-t border-gray-300 py-1">
                    <div className="w-3/5 flex text-lg uppercase truncate">
                      <div className="font-bold">Customer :</div>
                      <div className="mx-2 truncate">{jobcard?.customer}</div>
                    </div>
                    <div className="w-2/5 flex justify-end text-green-700 text-xl font-bold uppercase">
                      {jobcard?.registration}
                    </div>
                  </div>
                </div>
                {jobcard?.items?.length > 0 ? (
                  <div className="p-2 bg-white">
                    {jobcard?.items?.map((item: any, index: number) => (
                      <div
                        className={`flex flex-col rounded-lg p-2 mb-1 hover:cursor-pointer`}
                        style={{
                          backgroundColor: bgColors[item?.status],
                          color: txColors[item?.status],
                        }}
                        key={index}
                      >
                        <div className="font-bold mb-1 grid grid-cols-12">
                          <div className="col-span-12 sm:col-span-8 truncate">
                            <div className="flex items-center">
                              <div
                                className=""
                                onClick={() =>
                                  handleUpdateExpand({
                                    item,
                                    isExpanded: item?.isExpanded,
                                  })
                                }
                              >
                                {item?.isExpanded ? (
                                  <TbFoldUp />
                                ) : (
                                  <TbFoldDown />
                                )}
                              </div>

                              <div
                                className=""
                                onClick={() => {
                                  setActiveJobcard(jobcard);
                                  setActiveIndex(index);
                                  setActiveItem(item);
                                  setDetailsModal(true);
                                }}
                              >
                                TASK {index + 1}.{item?.description}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-12 sm:col-span-4 flex sm:justify-end capitalize">
                            {item?.status}
                          </div>
                        </div>

                        {item?.isExpanded && (
                          <div
                            className="flex justify-between"
                            onClick={() => {
                              setActiveJobcard(jobcard);
                              setActiveIndex(index);
                              setActiveItem(item);
                              setDetailsModal(true);
                            }}
                          >
                            <div className="flex">
                              <div className="mb-2">
                                {item?.technician && (
                                  <img
                                    src={item?.technician?.avatar || avatar}
                                    style={{
                                      width: 110,
                                      backgroundColor: "#ECECEC",
                                      borderRadius: 10,
                                    }}
                                    className="w-32 h-32 aspect-[4/4] border object-cover"
                                  />
                                )}
                              </div>
                              <div className="flex flex-col justify-between">
                                <div className="flex flex-col mx-2">
                                  {item?.technician?.name ? (
                                    <div className="flex items-center font-bold text-lg">
                                      <RiUser3Line />
                                      <span className="ml-2">
                                        {item?.technician?.name}
                                      </span>
                                    </div>
                                  ) : (
                                    <button className="flex items-center font-bold text-lg border px-2">
                                      <span className="">
                                        Assign Technician
                                      </span>
                                    </button>
                                  )}
                                  <div
                                    className="flex mb-1"
                                    style={{ fontSize: 12 }}
                                  >
                                    <ItemTime
                                      tasks={item?.tasks}
                                      expected={item?.expectedHours}
                                    />
                                  </div>
                                  <div
                                    className="flex items-center mb-1"
                                    style={{ fontSize: 12 }}
                                  >
                                    <ItemTime tasks={item?.tasks} />
                                  </div>
                                  <div
                                    className="flex items-cente mb-1"
                                    style={{ fontSize: 12 }}
                                  >
                                    <div className="w-24 font-bold">
                                      Efficiency{" "}
                                    </div>
                                    <div>
                                      :
                                      {getEfficiency(
                                        getItemHrs(item?.tasks),
                                        item?.expectedHours
                                      )}
                                      %
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="h-32">
                              <Timer
                                estimate={item?.expectedHours}
                                actual={getItemHrs(item?.tasks)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-2 bg-white flex flex-col items-center mt-6 h-full">
                    <div className="">
                      <AiOutlineFileExclamation size={40} color="#6b7280" />
                    </div>
                    <div className="text-gray-700">No Task Added</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <Details
        isVisible={detailsModal}
        setVisible={setDetailsModal}
        item={activeItem}
        itemId={activeItem?._id}
        _id={activeJobcard?._id}
        index={activeIndex}
        jobcard={activeJobcard}
      />

      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        refetch={refetch}
        jobcard={activeJobcard}
        _id={activeJobcard?._id}
      />

      <UpdateStation
        isVisible={stationModal}
        setVisible={setStationModal}
        refetch={refetch}
        jobcard={activeJobcard}
        _id={activeJobcard?._id}
      />
    </div>
  );
}
