import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchStations = async () => {
  const uri = `stations`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateStation = async (data: any) => {
  const uri = `stations`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateStation = async ({ data, _id }: any) => {
  const uri = `stations/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteStation = async ({ _id }: any) => {
  const uri = `stations/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchStations,
  handleCreateStation,
  handleUpdateStation,
  handleDeleteStation,
};
