import getTaskHrsHuman from '../../../../utils/getTaskHrsHuman'

export default function TaskHumanTime({actions}: any) {
    const {hours, minutes} = getTaskHrsHuman(actions)
  return (
    <div className="flex justify-end item-center">
    {hours > 0 && <div className="mr-1">{hours} Hrs </div>}
    {minutes > 0 && <div className="">{minutes} Mins </div>}
  </div>
  )
}
