import { TRACE_BASE_URL } from "../utils/urls";

const patchTrace = async ({ data, uri }: any) => {
  const response = await fetch(`${TRACE_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 200) {
    return Promise.reject("Something went wrong");
  }
  return response.json();
};

export default patchTrace;
