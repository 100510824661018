import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useState } from "react";
import { TbSignature } from "react-icons/tb";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";

import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import avatar from "../../../assets/images/technician.jpg";

import CreateTechnician from "./components/CreateTechnician";
import UploadSign from "./components/UploadSign";
import UpdateTechnician from "./components/UpdateTechnician";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { postMail } from "../../../services/postMail";
import BackNav from "../../../components/toolbars/BackNav";
import { handleFetchTechnicians } from "../../../api/technicians";
import UpdateStatus from "./components/UpdateStatus";
import Moment from "react-moment";
import UploadAvatar from "./components/UploadAvatar";
import DeleteTechnician from "./components/DeleteTechnician";

export default function Technicians() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [avatarModal, setAvatarModal] = useState<boolean>(false);
  const [signModal, setSignModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: technicians,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: technicians?.data?.length,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "KJL ID",
      width: 50,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.empID || "-"}</span>;
      },
    },
    {
      title: "CODE",
      width: 40,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.code || "-"}</span>;
      },
    },
    {
      title: "AVATAR",
      width: 60,
      render: (record: any) => (
        <button
          onClick={() => {
            setSelected({ ...record });
            setAvatarModal(true);
          }}
          className="bg-red-100"
        >
          {record?.avatar ? (
            <div className="h-10 w-10">
              <img
                src={record?.avatar}
                alt=""
                style={{ width: 400, backgroundColor: "#ECECEC" }}
                 className="w-full aspect-[4/5] object-cover"
              />
            </div>
          ) : (
            <div className="h-10 w-10">
              <img
                src={avatar}
                alt=""
                style={{ width: 400, backgroundColor: "#ECECEC" }}
                className="w-full aspect-[4/4] border object-contain"
              />
            </div>
          )}
        </button>
      ),
    },
    {
      title: "NAME",
      width: 160,
      render: (record: any) => {
        return <div><span className="text-gray-700">{record?.name || "-"}</span>
        
        <div className="flex capitalize">
          {record?.isActive ? (
            <button
              onClick={() => {
                setSelected({ ...record });
                setStatusModal(true);
              }}
              className="flex items-center"
            >
              {record?.isDeleted ? (
                <span className="text-red-700">ARCHIEVED</span>
              ) : (
                <span className="text-gray-700 capitalize">
                  {record?.status || "-"}
                </span>
              )}
              <div className="mx-2">
                <FiEdit />
              </div>
            </button>
          ) : (
            <button
              onClick={() => {
                setSelected({ ...record });
                setStatusModal(true);
              }}
              className="flex flex-col"
            >
              <div className="flex items-center capitalize">
                {record?.status}, From
                <div className="mx-2">
                  <FiEdit />
                </div>
              </div>
              <div className="flex">
                <Moment format="DD/MM/YYYY">{record?.leaveStart}</Moment>
                <span className="mx-2">To</span>
                <Moment format="DD/MM/YYYY">{record?.leaveEnd}</Moment>
              </div>
            </button>
          )}
        </div>
        </div>;
      },
    },
    {
      title: "EMAIL",
      width: 120,
      render: (record: any) => (
        <span className="text-gray-700">{record?.email || "-"}</span>
      ),
    },
    {
      title: "MOBILE",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">{record?.mobile || "-"}</span>
      ),
    },
    {
      title: "STATION",
      width: 80,
      render: (record: any) => (
        <span className="text-gray-700">{record?.station?.name || "-"}</span>
      ),
    },
    {
      title: "SKILLS",
      width: 100,
      render: (record: any) => (
        <div className="text-gray-700 truncate">
          {record?.skill?.description || "-"}
        </div>
      ),
    },
    {
      title: "TEAM",
      width: 50,
      render: (record: any) => (
        <span className="text-gray-700">{record?.team?.name || "-"}</span>
      ),
    },

    {
      title: "TARGET HRS",
      width: 70,
      render: (record: any) => (
        <span className="text-gray-700">{record?.targetHrs || "-"}</span>
      ),
    },
    {
      title: "ACTION",
      width: 80,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setSignModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <TbSignature />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-yellow-100 hover:bg-yellow-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <RiDeleteBin5Line />
          </button>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = technicians?.data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Code: item?.code === null ? "-" : item?.code,
        "KJL ID": item?.empID === null ? "-" : item?.empID,
        Address: item?.address === null ? "-" : item?.address,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Status: item?.status === null ? "-" : item?.status,
        Active: item?.isActive ? "Yes" : "No",
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Technicians</div>
            <div className="text-gray-600">Manage Technicians</div>
          </div>

          <div className="flex">
            <Popover
              content={
                <>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    form={emailForm}
                    onFinish={sendMail}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: "Email is Required" }]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        { required: true, message: "Subject is Required" },
                      ]}
                    >
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <div className="flex flex-row justify-between">
                      <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                        <Button type="primary" htmlType="submit" danger>
                          {isMailLoading ? "Sending ..." : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                        <Button
                          onClick={() => {
                            toggleMessageModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              }
              title="Message Content"
              trigger="focus"
              placement="left"
              open={messageModal}
            >
              <Tooltip title="Send Email">
                <button
                  className="flex ml-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
                  onClick={async () => {
                    toggleMessageModal(true);
                  }}
                >
                  <RiMailSendLine size={21} />
                </button>
              </Tooltip>
            </Popover>
            <Tooltip title="Export To Excel">
              <button
                color="#E50000"
                className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
                onClick={() =>
                  ExportToExcel(
                    technicians?.data?.map((item: any, index: number) => {
                      return {
                        "S/N": index + 1,
                        Name: item?.name === null ? "-" : item?.name,
                        Code: item?.code === null ? "-" : item?.code,
                        "KJL ID": item?.empID === null ? "-" : item?.empID,
                        Address: item?.address === null ? "-" : item?.address,
                        Email: item?.email === null ? "-" : item?.email,
                        Mobile: item?.mobile === null ? "-" : item?.mobile,
                        Status: item?.status === null ? "-" : item?.status,
                        Active: item?.isActive ? "Yes" : "No",
                      };
                    }),
                    "Technicians.xlsx"
                  )
                }
              >
                <RiFileExcel2Line size={20} />
              </button>
            </Tooltip>
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1  h-10 hover:bg-gray-100"
            >
              New
            </button>
          </div>
        </div>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <Table
              size="small"
              bordered
              dataSource={technicians?.data}
              columns={columns}
              scroll={{ y: 660, x: 0 }}
              rowKey="id"
              pagination={{ pageSize: 10 }}
              loading={isLoading}
              onChange={(e) => onChange(e)}
            />
          </div>
        )}
      </div>

      <CreateTechnician
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={handleRefetch}
      />

      <UpdateTechnician
        isVisible={updateModal}
        setVisible={setUpdateModal}
        refetch={refetch}
        technician={selected}
      />

      <DeleteTechnician
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        refetch={refetch}
        technician={selected}
      />

      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        refetch={refetch}
        technician={selected}
      />

      <UploadAvatar
        isVisible={avatarModal}
        setVisible={setAvatarModal}
        refetch={refetch}
        technician={selected}
      />

      <UploadSign
        isVisible={signModal}
        setVisible={setSignModal}
        selected={selected}
        refetch={refetch}
      />
    </div>
  );
}
