import moment from "moment";

const disabledDate = (current: any) => {
    // Disable dates before today
    const isPastDate = current && current < moment().startOf('day');
    
    // Disable weekends (Saturday = 6, Sunday = 0)
    const isWeekend = current && (current.day() === 0 || current.day() === 6);
    
    return isPastDate || isWeekend;
  };


  export default disabledDate

  