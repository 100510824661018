import getBudgetHrs from "../../../utils/getBudgetHrs";
import getEfficiency from "../../../utils/getEfficiency";
import getPercent from "../../../utils/getPercent";
import ScorePercent from "./ScorePercent";

export default function Technician({ technician, dates }: any) {
  const allowed = getBudgetHrs(technician?.targetHrs, dates?.period);
  const taken = technician.totalTime.toFixed(2);
  const score = getEfficiency(taken, allowed);
  let percent = getPercent(taken, allowed);

  let ms = <span className="text-green-500">Spent {percent}% of Total allowed time</span>
  if(Number(percent) > 100){
   ms = <span className="text-red-500">Spent {Number(percent)-100}% more of the Total allowed time</span>

  }
  return (
    <div
      className="col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-2 bg-blue-100 p-2 rounded-lg"
      key={technician.id}
    >
      <div className="flex">
        <div className="mr-2">
          <img
            src={technician?.avatar}
            style={{
              width: 60,
              backgroundColor: "#ECECEC",
              borderRadius: 10,
            }}
            className="w-20 h-20 aspect-[4/4] border object-cover"
          />
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex justify-between ">
            <div className="flex flex-col">
              <div className="font-bold text-lg text-black flex-1">
                {technician?.name || "UnAllocated"}
              </div>
              <div className="text-xs italic">{ms}</div>
            </div>
            <div className="flex justify-end">
              <ScorePercent score={score || 0} pathColor={"#426245"} />
            </div>
          </div>
          <div className="">
            <div className="flex justify-between">
              <div className="mr-2">Time Allowed :</div>
              <div className="">{getBudgetHrs(technician?.targetHrs, dates?.period)}</div>
            </div>
            <div className="flex justify-between">
              <div className="mr-2"> Time Taken:</div>
              <div className=""> {technician.totalTime.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
