import { useEffect } from "react";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";

export default function Paused({ jobcards, handleSelectedStatus }: any) {
  const filtered = jobcards?.data?.filter(
    (jobcard: any) => jobcard?.status === "paused"
  );

  useEffect(() => {}, [jobcards]);

  return (
    <button
      onClick={() => handleSelectedStatus({ status: "paused", jobcards: filtered })}
      className="col-span-12 sm:col-span-3 flex items-center p-2 hover:bg-red-200"
    >
      <div
        className=""
        style={{
          backgroundColor: "#FACC16",
          width: 4,
          height: 15,
          borderRadius: 5,
        }}
      ></div>
      <div className="ml-2">Paused -</div>
      <div className="font-bold px-2  flex justify-center items-center">
        {filtered?.length || 0}
      </div>
      <div className="">
        <HiOutlineChevronDoubleRight />
      </div>
    </button>
  );
}
