import { Link } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";

export default function BackNav({ link }: any) {
  return (
    <Link
      to={`${link}`}
      className="bg-white flex items-center py-2 px-2 hover:text-toyota border-b border-gray-200"
    >
      <div className="">
        <GoChevronLeft size={20}/>
      </div>
      <div className="mx-1">Back</div>
    </Link>
  );
}
