import { useQuery } from "@tanstack/react-query";
import { handleFetchJobcardsUnpaginated } from "../../../api/jobcards";
import { useEffect, useState } from "react";
import Technician from "./Technician";

interface Technician {
  id: string;
  name: string;
  avatar: string;
  targetHrs: number;
  totalTime: number;
}

interface Jobcard {
  _id: string;
  items: {
    tasks: {
      actions: {
        isPause: boolean;
        startTime: string;
        endTime?: string | null;
      }[];
    }[];
    technician: {
      _id: string;
      name: string;
      station: string;
      avatar: string;
      targetHrs: number
    };
  }[];
}

export default function Technicians({ station, dates }: any) {
  const [data, setData] = useState<Technician[]>([]);

  const params = `station=${station?._id}&startDate=${dates?.startDate}&endDate=${dates?.endDate}`;

  const {
    data: jobcards,
    isLoading,
    isError,
  } = useQuery<Jobcard[], Error>({
    queryKey: ["techniciansData", params],
    queryFn: () => handleFetchJobcardsUnpaginated({ params }),
    enabled: !!station,
    select: (data: any) => data?.payload || [],
  });

  async function calculateActiveTime(data: Jobcard): Promise<Technician[]> {
    const technicianTimeMap: { [key: string]: Technician } = {};

    for (const item of data.items) {
      if (station?._id !== "") {
        if (item?.technician?.station !== station?._id) {
          continue;
        }
      }
      for (const task of item.tasks) {
        const technicianId = item?.technician?._id;
        const technicianName = item?.technician?.name?.trim();
        const technicianAvt = item?.technician?.avatar;
        const technicianHrs = item?.technician?.targetHrs;

        for (const action of task.actions) {
          if (!action.isPause) {
            const startTime: any = new Date(action?.startTime);
            const endTime: any = action?.endTime
              ? new Date(action.endTime)
              : new Date();
            const duration = (endTime - startTime) / 1000 / 60 / 60; // Duration in minutes

            if (!technicianTimeMap[technicianId]) {
              technicianTimeMap[technicianId] = {
                id: technicianId,
                name: technicianName,
                avatar: technicianAvt,
                targetHrs: technicianHrs,
                totalTime: 0,
              };
            }

            technicianTimeMap[technicianId].totalTime += duration;
          }
        }
      }
    }

    return Object.values(technicianTimeMap);
  }

  useEffect(() => {
    async function fetchData() {
      if (jobcards && !isLoading && !isError) {
        const aggregatedTechnicianData: { [key: string]: Technician } = {};

        for (const jobcard of jobcards) {
          const technicianTimeMap = await calculateActiveTime(jobcard);

          for (const technicianData of technicianTimeMap) {
            const { id, name, totalTime, avatar, targetHrs } = technicianData;

            if (!aggregatedTechnicianData[id]) {
              aggregatedTechnicianData[id] = {
                id,
                name,
                avatar,
                targetHrs: targetHrs ? targetHrs : 0,
                totalTime: 0,
              };
            }

            aggregatedTechnicianData[id].totalTime += totalTime;
          }
        }

        setData(Object.values(aggregatedTechnicianData));
      }
    }

    fetchData();
  }, [jobcards, isLoading, isError, dates, station]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="bg-white pb-1 mb-4">
      <div className="border-b py-3 px-4">
        <div>TECHNICIAN EFFICIENCY</div>
      </div>
      <div className=" flex m-2">
        <div className="grid grid-cols-12 gap-2 text-gray-500 w-full">
          {data.map((technician) => (
            <Technician technician={technician} dates={dates} />
          ))}
        </div>
      </div>
    </div>
  );
}
