import getTimeHrs from "./getTimeHrs";

const getTaskPausedHrs = (actions: any) => {
  let hrs: any = 0;
  actions?.forEach((item: any) => {
    if(item?.isPause && !item?.isEOD){
      hrs += getTimeHrs(item.startTime, item.endTime);
    }
  });
  return parseFloat(hrs).toFixed(1)
};

export default getTaskPausedHrs;
