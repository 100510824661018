import BackNav from "../../../components/toolbars/BackNav";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useState } from "react";
import Something from "../../../components/shared/Something";
import CreateStation from "./components/CreateStation";
import UpdateStation from "./components/UpdateStation";
import DeleteStation from "./components/DeleteStation";
import location from "../../../components/icons/location.svg";
import { handleFetchStations } from "../../../api/stations";
import { useQuery } from "@tanstack/react-query";

export default function Stations() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const {
    data: stations,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Stations</div>
            <div className="text-gray-600">Manage Stations</div>
          </div>
          <div className="">
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>

        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="pb-1">
            <div className="grid grid-cols-12 gap-1 m-4">
              {stations?.data?.map((item: any, index: number) => (
                <div
                  className="col-span-6 sm:col-span-4 md:col-span-2 bg-gray-100 flex  flex-col items-center py-6"
                  key={index}
                >
                  <div className="w-24 h-24 bg-gray-200 rounded-full flex justify-center items-center">
                    <img src={location} width={30} />
                  </div>
                  <div className="font-bold pt-2">{item?.name}</div>
                  <div className="py-2 text-gray-500">{item?.branch || "-"}</div>
                  <div className="flex bg-white p-1">
                    <button
                      className=" border mr-1 px-2 py-1"
                      onClick={() => {
                        setSelected(item);
                        setUpdateModal(true);
                      }}
                    >
                      <FiEdit />
                    </button>
                    <button
                      className=" border ml-1 px-2 py-1"
                      onClick={() => {
                        setSelected(item);
                        setDeleteModal(true);
                      }}
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <CreateStation
        isVisible={createModal}
        setVisible={setCreateModal}
        refetch={refetch}
      />

      <UpdateStation
        isVisible={updateModal}
        setVisible={setUpdateModal}
        selected={selected}
      />

      <DeleteStation
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        selected={selected}
      />
    </div>
  );
}
