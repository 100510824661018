import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal, Form, message, Button, Row, Col, Select } from "antd";
import {
  handleMakeJobcardClosed,
} from "../../../../api/jobcards";
import TextArea from "antd/es/input/TextArea";
import { handleFetchGenerics } from "../../../../api/generics";

const { Option } = Select;

export default function UpdateGeneric({
  isVisible,
  setVisible,
  jobcard,
  _id,
}: any) {
  const [selected, setSelected] = useState<any>(jobcard?.status);
  const [code, setCode] = useState<any>("");
  const [nots, setNots] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { data: generics } = useQuery({
    queryKey: ["generics"],
    queryFn: handleFetchGenerics,
  });

  const { mutate: makeClosed } = useMutation({
    mutationFn: handleMakeJobcardClosed,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmitClose = async () => {
    const { genericCode, description } = await form.validateFields();
    let formatedDesc =
      description !== undefined
        ? `${code}: ${description}`
        : `${code}: ${desc},  ${nots}`;
    await makeClosed({
      _id,
      data: {
        status: "completed",
        isClosed: true,
        genericCode,
        description: formatedDesc,
      },
    });
    setVisible(false);
  };


  const handleSelectGeneric = (generic: any) => {
    const selected = generics?.data?.filter(
      (item: any) => item?._id === generic
    );
    setCode(selected[0]?.code);
    setNots(selected[0]?.notes);
    setDesc(selected[0]?.description);
    form.setFieldsValue({ notes: selected[0]?.notes });
  };

  const genericOptions = generics?.data?.map((generic: any, index: number) => (
    <Option value={generic?._id} key={index}>
      {`${generic?.code} - ${generic?.description}`}
    </Option>
  ));

  useEffect(() => {
    setSelected(jobcard?.status);
  }, [jobcard]);

  return (
    <Modal
      open={isVisible}
      title={`Update Notes / Generic Code: WIP ${jobcard?.wip}`}
      onCancel={() => setVisible(false)}
      footer={null}
    >
   
        <div className="">
          <div className="text-green-600">Status: Completed</div>
          <Form
            id="closeJobcard"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmitClose}
            layout="vertical"
          >
            <div className="mb-4">
              This will mark this Jobcard as{" "}
              <span className="text-red-600">Closed</span>
            </div>

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="genericCode"
                  label={"Jobcard Generic Code"}
                  rules={[
                    {
                      required: true,
                      message: "Please select jobcard code",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and Select Code"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(generic) => handleSelectGeneric(generic)}
                  >
                    {genericOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="description"
                  label="Jobcard Description Notes ( Optional )"
                  rules={[
                    {
                      required: false,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Description notes"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                danger
                type="primary"
                htmlType="button"
                onClick={() => onSubmitClose()}
              >
                Close Jobcard
              </Button>
              <Button
                danger
                htmlType="button"
                className="mx-2"
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
    </Modal>
  );
}
