import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { handleCreateTeam } from "../../../../api/teams";
import { handleFetchTechnicians } from "../../../../api/technicians";
const { Option } = Select;

export default function CreateTeam({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleCreateTeam,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: technicians } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const technicianOptions = technicians?.data?.map(
    (technician: any, index: number) => (
      <Option value={technician?._id} key={index}>
        {technician?.name}
      </Option>
    )
  );

  const onSubmit = async () => {
    const { name, description, leader } = await form.getFieldsValue();
    const data = {
      name,
      leader,
      description,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="New Team"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createTeam"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createTeam"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Team Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="leader"
              label={"Leader"}
              rules={[
                {
                  required: false,
                  message: "Please select technician",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Technician"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {technicianOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description ( Optional )"
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
