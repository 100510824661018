
const getItmExpHrs = (tasks: any) => {
  let hrs: any = 0;
    tasks?.forEach((task: any) => {
      hrs += task?.expectedHours;
    });
  return parseFloat(hrs).toFixed(1);
};

export default getItmExpHrs;
