import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal, message } from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import { handleUpdateJobcardStation } from "../../../../api/jobcards";
import { handleFetchStations } from "../../../../api/stations";

export default function UpdateStation({
  isVisible,
  setVisible,
  jobcard,
  _id,
}: any) {
  const [selected, setSelected] = useState<any>(jobcard?.station?._id);
  const queryClient = useQueryClient();
  const { data: stations } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  const { mutate: updateStation } = useMutation({
    mutationFn: handleUpdateJobcardStation,
    onSuccess: () => {
      message.success("Moved Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async (station: any) => {
    await updateStation({ _id: jobcard?._id, data: { station } });
  };

  useEffect(() => {
    setSelected(jobcard?.station?._id);
  }, [jobcard]);

  return (
    <Modal
      open={isVisible}
      title={`Update Station: WIP ${jobcard?.wip}`}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="">
        {stations?.data?.map((station: any, index: number) => (
          <button
            onClick={() => {
              onSubmit(station?._id);
              setSelected(station?._id);
            }}
            className={`flex items-center mb-2 bg-gray-100 w-full p-2`}
            key={index}
          >
            <div className="w-8 h-8 border border-gray-300 flex justify-center items-center">
              {selected === station?._id ? <AiOutlineCheck /> : ""}
            </div>
            <div className="ml-2">{station?.name}</div>
          </button>
        ))}
      </div>
    </Modal>
  );
}
