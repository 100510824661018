import getTaskPausedHrsHuman from '../../../../utils/getTaskPausedHrsHuman'

export default function TaskPausedHumanTime({actions}: any) {
    const {hours, minutes} = getTaskPausedHrsHuman(actions)
  return (
    <div className="flex justify-end item-center">
    {hours > 0 && <div className="mr-1">{hours} Hrs </div>}
    {minutes > 0 && <div className="">{minutes} Mins </div>}
  </div>
  )
}
