import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchRoles = async () => {
  const uri = `roles`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateRole = async (data: any) => {
  const uri = `roles`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateRole = async ({ data, _id }: any) => {
  const uri = `roles/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteRole = async ({ _id }: any) => {
  const uri = `roles/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchRoles,
  handleCreateRole,
  handleUpdateRole,
  handleDeleteRole,
};
