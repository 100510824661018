const bgColors: any = {
  new: "#3b82f6",
  awaiting: "#808080",
  "in-progress": "#3b82f6",
  paused: "#facc15",
  completed: "#16a34a",
};

const txColors: any = {
  new: "#FFFFFF",
  awaiting: "#FFFFFF",
  "in-progress": "#FFFFFF",
  paused: "#FFFFFF",
  completed: "#FFFFFF",
};

  export {bgColors, txColors}