import { GoArrowUpRight } from "react-icons/go";
import bg from "../../../assets/images/completed.png";
import { useQuery } from "@tanstack/react-query";
import { handleJobcardsCountByStatus } from "../../../api/jobcards";
import { MdArrowRight } from "react-icons/md";
import { useEffect } from "react";
import Moment from "react-moment";
export default function Completed({
  handleSelectedStatus,
  station,
  dates,
}: any) {
  const { data: jobcards, refetch } = useQuery({
    queryKey: ["jobcardsCount", "completed"],
    queryFn: () =>
      handleJobcardsCountByStatus({ status: "completed", station, dates }),
  });

  const styles = {
    container: {
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: "#30A34A",
      width: "100%",
      color: "white",
      padding: "1em",
      borderRadius: "1em",
    },
  };

  useEffect(() => {
    refetch();
  }, [station, dates]);

  return (
    <div className="col-span-12 sm:col-span-3">
      <button
        className="w-full"
        onClick={() => handleSelectedStatus({ status: "completed" })}
      >
        <div style={styles.container}>
          <div className="flex justify-between">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col items-start">
                <div className="text-2xl font-bold">Completed</div>
                <div className="text-2xl">Jobs</div>
              </div>
              <div className="flex text-gray-100">
                <Moment format="DD MMM, YYYY">{dates?.startDate}</Moment>
                {dates?.isRange && (
                  <div className="">
                    <span className="mx-2">-</span>
                    <Moment format="DD MMM, YYYY">{dates?.endDate}</Moment>
                  </div>
                )}
              </div>
              <div className="flex items-center text-sm">
                <div>Show Details</div>
                <MdArrowRight size={20} />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-6xl font-bold mb-2">{jobcards?.payload}</div>
              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-green-600">
                <GoArrowUpRight />
              </div>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}
