import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message, Checkbox } from "antd";
import { useEffect } from "react";
import { handleUpdateRole } from "../../../../api/roles";
import TextArea from "antd/es/input/TextArea";

export default function UpdateRole({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateRole,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["roles"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ name: selected?.name });
    form.setFieldsValue({ description: selected?.description });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Role"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateRole"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateRole"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label="Description"
              initialValue={selected?.description}
              rules={[
                {
                  required: false,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
