import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, message, Modal, Table } from "antd";
import { handleFillAttendance } from "../../../api/attendance";
import moment from "moment";
import { GoCheckbox } from "react-icons/go";
import { AiOutlineWarning } from "react-icons/ai";

export default function TakeAttendance({
  attendance,
  isLoading,
  date,
  isVisible,
  setVisible,
}: any) {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: handleFillAttendance,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["attendances"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });
  const handleAssignAttendance = async ({ isPresent, record }: any) => {
    await mutate({
      data: { isPresent, isFilled: true },
      _id: attendance?.data?._id,
      technicianId: record?._id,
    });
  };

  const columns = [
    {
      title: "NAME",
      width: 150,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="">
            <img
              src={record?.avatar}
              alt=""
              style={{
                width: 40,
              }}
              className="w-full aspect-[4/4] object-contain"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-black">{record?.name}</div>
            <div className="text-gray-600 text-xs">
              {moment(date).format("LL")}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "ACTION",
      width: 90,
      render: (record: any) => (
        <div className="flex items-center">
          {record?.isFilled ? (
            <div className="">
              {record?.isPresent ? (
                <div className="text-green-500 flex items-center">
                  <GoCheckbox />
                  <span className="mx-1">Present</span>
                </div>
              ) : (
                <div className="text-red-500 flex items-center">
                  <AiOutlineWarning />
                  <span className="mx-1">Absent</span>
                </div>
              )}
            </div>
          ) : (
            <div className="flex m-2">
              <button
                onClick={() => {
                  handleAssignAttendance({
                    isPresent: true,
                    record,
                  });
                }}
                className="flex justify-center items-center mx-1 text-gray-700 border border-green-600 rounded-sm px-2 bg-green-200 hover:bg-green-300 cursor-pointer"
              >
                Present
              </button>
              <button
                onClick={() => {
                  handleAssignAttendance({
                    isPresent: false,
                    record,
                  });
                }}
                className="flex justify-center items-center text-gray-700 border border-red-600 rounded-sm px-2 bg-red-200 hover:bg-red-300 cursor-pointer"
              >
                Absent
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <Modal
      open={isVisible}
      title={`ATTENDANCE :  ${moment(date).format('Do MMM YYYY')}`}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div>
        {attendance?.data?.technicians.length > 0 ? (
          <Table
            columns={columns}
            dataSource={attendance?.data?.technicians || []}
            size="small"
            bordered
            pagination={{ pageSize: 20 }}
            scroll={{ y: 525 }}
            loading={isLoading}
            rowKey="id"
          />
        ) : (
          <div className="">
            <div className="text-center my-8">No Attendance</div>
          </div>
        )}
      </div>
    </Modal>
  );
}
