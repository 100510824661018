
const getJobExpHrs = (items: any) => {
  let hrs: any = 0;
  items?.forEach((item: any) => {
    item.tasks?.forEach((task: any) => {
      hrs += task?.expectedHours;
    });
  });
  return parseFloat(hrs).toFixed(1);
};

export default getJobExpHrs;
