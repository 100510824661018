import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal, Form, message, Button, Row, Col, Select } from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import {
  handleMakeJobcardClosed,
  handleUpdateJobcardStatus,
} from "../../../../api/jobcards";
import TextArea from "antd/es/input/TextArea";
import { handleFetchGenerics } from "../../../../api/generics";

const { Option } = Select;

export default function UpdateStatus({
  isVisible,
  setVisible,
  jobcard,
  _id,
}: any) {
  const [selected, setSelected] = useState<any>(jobcard?.status);
  const [code, setCode] = useState<any>("");
  const [nots, setNots] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const queryClient = useQueryClient();

  const [close, setClose] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { data: generics } = useQuery({
    queryKey: ["generics"],
    queryFn: handleFetchGenerics,
  });

  const { mutate: updteStatus } = useMutation({
    mutationFn: handleUpdateJobcardStatus,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: makeClosed } = useMutation({
    mutationFn: handleMakeJobcardClosed,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async (status: any) => {
    const data = {
      status,
      isClosed: false,
    };
    await updteStatus({ _id, data });
    form.resetFields();
    setVisible(false);
  };

  const onSubmitClose = async () => {
    const { genericCode, description } = await form.validateFields();
    let formatedDesc =
      description !== undefined
        ? `${code}: ${description}`
        : `${code}: ${desc},  ${nots}`;
    await makeClosed({
      _id,
      data: {
        status: "completed",
        isClosed: true,
        genericCode,
        description: formatedDesc,
      },
    });
    setVisible(false);
  };

  const status = [
    { id: 1, name: "Awaiting", value: "awaiting", isSupported: true },
    { id: 2, name: "Paused", value: "paused", isSupported: true },
    { id: 3, name: "In-Progress", value: "in-progress", isSupported: true },
  ];

  const handleSelectGeneric = (generic: any) => {
    const selected = generics?.data?.filter(
      (item: any) => item?._id === generic
    );
    setCode(selected[0]?.code);
    setNots(selected[0]?.notes);
    setDesc(selected[0]?.description);
    form.setFieldsValue({ notes: selected[0]?.notes });
  };

  const genericOptions = generics?.data?.map((generic: any, index: number) => (
    <Option value={generic?._id} key={index}>
      {`${generic?.code} - ${generic?.description}`}
    </Option>
  ));

  useEffect(() => {
    setSelected(jobcard?.status);
  }, [jobcard]);

  return (
    <Modal
      open={isVisible}
      title={`Update Status: WIP ${jobcard?.wip}`}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {!close ? (
        <div className="">
          {status?.map((item: any, index: number) => (
            <button
              onClick={() => {
                onSubmit(item?.value);
                setSelected(item?.value);
              }}
              className={`flex items-center mb-2 bg-gray-100 w-full p-2  ${
                item?.isSupported ? "" : "text-gray-200"
              }`}
              key={index}
            >
              <div className="w-8 h-8 border border-gray-300  flex justify-center items-center">
                {selected === item?.value ? <AiOutlineCheck /> : ""}
              </div>
              <div className="ml-2">{item?.name}</div>
            </button>
          ))}

          <button
            onClick={() => setClose(true)}
            type="button"
            className={`flex items-center mb-2 bg-gray-100 w-full p-2`}
          >
            <div className="w-8 h-8 border border-gray-300  flex justify-center items-center"></div>
            <div className="ml-2">Complete</div>
          </button>
        </div>
      ) : (
        <div className="">
          <button
            type="button"
            onClick={() => setClose(false)}
            className="flex items-center pt-4 pb-2"
          >
            <BsChevronLeft />
            <div className="ml-1">Back</div>
          </button>

          <div className="text-green-600">Status: Completed</div>
          <Form
            id="closeJobcard"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmitClose}
            layout="vertical"
          >
            <div className="mb-4">
              This will mark this Jobcard as{" "}
              <span className="text-red-600">Closed</span>
            </div>

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="genericCode"
                  label={"Jobcard Generic Code"}
                  rules={[
                    {
                      required: true,
                      message: "Please select jobcard code",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and Select Code"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(generic) => handleSelectGeneric(generic)}
                  >
                    {genericOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="description"
                  label="Jobcard Description Notes ( Optional )"
                  rules={[
                    {
                      required: false,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Description notes"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                danger
                type="primary"
                htmlType="button"
                onClick={() => onSubmitClose()}
              >
                Close Jobcard
              </Button>
              <Button
                danger
                htmlType="button"
                className="mx-2"
                onClick={() => setClose(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
}
