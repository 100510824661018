function getHumanTime(decimalHours: any) {
  const hours = Math.floor(decimalHours);

  const minutesDecimal = (decimalHours - hours) * 60;
  const minutes = Math.floor(minutesDecimal);

  const seconds = Math.round((minutesDecimal - minutes) * 60);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

export default getHumanTime
