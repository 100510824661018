import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";


const handleFetchReasons = async () => {
  const uri = `reasons`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateReason = async (data: any) => {
  const uri = `reasons`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateReason = async ({ data, _id }: any) => {
  const uri = `reasons/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleDeleteReason = async ({ _id }: any) => {
  const uri = `reasons/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchReasons,
  handleCreateReason,
  handleUpdateReason,
  handleDeleteReason,
};
