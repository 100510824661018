import { Drawer } from "antd";
import LineItem from "../../manage/jobcards/components/LineItem";
import { useEffect, useState } from "react";
import { handleFetchJobcard } from "../../../api/jobcards";
import { useQuery } from "@tanstack/react-query";
import { FaCheck } from "react-icons/fa";
import AddReason from "../../manage/jobcards/components/AddReason";
import AddEOD from "../../manage/jobcards/components/AddEOD";

export default function Details({
  isVisible,
  setVisible,
  item,
  itemId,
  index,
  jobcard,
  _id,
}: any) {
  const [reasonTaskModal, setReasonTaskModal] = useState<boolean>(false);
  const [eodTaskModal, setEodTaskModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedTask, setSelectedTask] = useState<any>({});
  const [ai, setAi] = useState<any>({});

  const { data: job } = useQuery({
    queryKey: ["jobcards", _id],
    queryFn: () =>
      _id === undefined ? null : handleFetchJobcard({ _id: _id }),
  });

  const getItem = () => {
    let i = job?.data?.items?.find((i: any) => i?._id === itemId);
    return i;
  };

  const handlePauseTask = ({ item, task }: any) => {
    setSelectedTask(task);
    setSelectedItem(item);
    setReasonTaskModal(true);
  };

  const handleEODTask = ({ item, task }: any) => {
    setSelectedTask(task);
    setSelectedItem(item);
    setEodTaskModal(true);
  };

  useEffect(() => {
    const i = getItem();
    setAi(i);
  }, [itemId, job]);
  return (
    <Drawer
      open={isVisible}
      title={
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="font-bold text-4xl">WIP {jobcard?.wip}</div>

            <div className="flex items-center">
              <span className="mr-2 text-lg">
                <span className="font-bold">TASK : </span>
              </span>
              <div className="flex text-gray-500 px-2 uppercase">
                {item?.rtscode}
              </div>
            </div>
          </div>
          <div className="">
            {editMode ? (
              <div
                onClick={() => setEditMode(false)}
                className="bg-green-400 h-10 flex items-center rounded-lg cursor-pointer"
              >
                <div className="flex items-center px-1">
                  <div className="rounded-lg mx-1">Edit</div>
                  <div className="rounded-lg bg-white h-8 w-8 flex justify-center items-center">
                    <FaCheck color="#16a34a" />
                  </div>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setEditMode(true)}
                className="bg-gray-200 h-10 flex items-center rounded-lg cursor-pointer"
              >
                <div className="flex items-center px-1">
                  <div className="rounded-lg bg-white h-8 w-8 flex justify-center items-center">
                    <FaCheck color="#6b7280" />
                  </div>
                  <div className="rounded-lg mx-1">Edit</div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      placement="right"
      width={1000}
      onClose={() => setVisible(!isVisible)}
      closable={false}
    >
      <LineItem
        item={ai}
        id={jobcard?._id}
        index={index}
        jobcard={jobcard}
        status={jobcard?.status}
        editMode={editMode}
        handlePauseTask={handlePauseTask}
        handleEODTask={handleEODTask}
      />

      <AddReason
        isVisible={reasonTaskModal}
        setVisible={setReasonTaskModal}
        jobcard={jobcard}
        task={selectedTask}
        item={selectedItem}
        id={jobcard?._id}
      />

      <AddEOD
        isVisible={eodTaskModal}
        setVisible={setEodTaskModal}
        jobcard={jobcard}
        task={selectedTask}
        item={selectedItem}
        id={jobcard?._id}
      />
    </Drawer>
  );
}
