import { Popover } from "antd";
import moment from "moment";
import { useState } from "react";
import { BsCaretDown } from "react-icons/bs";
import { FiList } from "react-icons/fi";
import { LuCalendarDays } from "react-icons/lu";
import Moment from "react-moment";

export default function Header({ stateHandler }: any) {
  const [isFiltered, setFiltered] = useState<boolean>(false);

  const today = new Date();

  const options = [
    {
      id: 1,
      name: "Today",
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      period: "day",
    },
    {
      id: 2,
      name: "This Week",
      startDate: moment().startOf("week"),
      endDate: moment().endOf("week"),
      period: "week",
    },
    {
      id: 3,
      name: "This Month",
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      period: "month",
    },
    {
      id: 4,
      name: "Year to Date",
      startDate: moment().startOf("year"),
      endDate: moment().endOf("month"),
      period: "year",
    },
  ];

  const clearDate = () => {
    setOption({
      id: 1,
      name: "Today",
      startDate: moment().startOf("day"),
      endDate: moment().startOf("day"),
      period: "day",
    });
    stateHandler({
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
      isRange: false,
      isAll: false,
    });
  };

  const [option, setOption] = useState<any>({
    id: 1,
    name: "Today",
    startDate: moment().startOf("day"),
    endDate: moment().startOf("day"),
    period: "day",
    isRange: false
  });

  const rangeOptions = (
    <div>
      {options?.map((item: any, index: number) => (
        <div
          className="flex flex-col justify-start items-start w-full"
          key={index}
        >
          <button
            onClick={() => {
              setOption(item);

              if (item.id !== 1) {
                setFiltered(true);
                stateHandler({
                  startDate: moment(new Date(item.startDate)).format(
                    "YYYYMMDD"
                  ),
                  endDate: moment(new Date(item.endDate)).format("YYYYMMDD"),
                  period: item?.period,
                  isRange: true,
                  isAll: false,
                });
              } else {
                setFiltered(false);
                stateHandler({
                  startDate: moment(new Date(item.startDate)).format(
                    "YYYYMMDD"
                  ),
                  endDate: moment(new Date(item.endDate)).format("YYYYMMDD"),
                  isRange: false,
                  period: item?.period,
                  isAll: false,
                });
              }
            }}
            className="py-2 border-t focus:outline-none w-full flex items-start"
          >
            {item?.name}
          </button>
        </div>
      ))}

      <div className="flex flex-col justify-start items-start w-full border-t-2">
        <button
          onClick={() => clearDate()}
          className="p-2 border-t focus:outline-none w-full flex items-start border mt-2 bg-gray-50 text-red-500"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex justify-between items-center border-b border-gray-300 py-1 px-4 bg-red-100">
      <div className="bg-red-100  border-gray-300">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-6">
            <div className="flex items-center justify-center sm:justify-start">
              <div className="bg-gray-100 h-8 rounded-xl w-20 flex justify-between items-center">
                <div className="h-7 w-10 bg-white mx-1 flex justify-center items-center rounded-xl">
                  <FiList size={20} color="#323232" />
                </div>
                <div className="h-7 w-10 bg-gray-100 mx-1 flex justify-center items-center rounded-xl">
                  <LuCalendarDays size={20} color="#323232" />
                </div>
              </div>

              <div className="flex border-l">
                  <span className="font-bold mx-2 uppercase">
                    <Moment format="MMM DD, YYYY">{today}</Moment>
                  </span>
                  <span className="text-gray-500">TODAY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <Popover content={rangeOptions} title="" placement="bottom">
          <button className="flex items-center justify-center cursor-pointer focus:outline-none border border-red-300 py-2 px-4 bg-red-200 rounded-md">
            <div className="">{option?.name}</div>
            <div className="ml-1">
              <BsCaretDown />
            </div>
          </button>
        </Popover>
      </div>
    </div>
  );
}
