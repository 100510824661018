import Completed from "./components/Completed";
import Inprogress from "./components/Inprogress";
import Awaiting from "./components/Awaiting";
import Paused from "./components/Paused";
import JobcardDetails from "./components/JobcardDetails";

import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Header from "../../components/shared/Header";
import { AuthContext } from "../../contexts/AuthContext";
import Technicians from "./components/Technicians";
import Reasons from "./components/Reasons";
import Attendance from "./components/Attendance";
import Availability from "./components/Availability";

export default function Dashboard() {
  const { station, auth, user } = useContext(AuthContext);
  const [detailsPanel, setDetailsPanel] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<any>("");

  const [dates, setDates] = useState<any>({
    startDate: moment().format("YYYYMMDD"),
    endDate: moment().format("YYYYMMDD"),
    period: "day",
    isRange: false,
    isAll: false,
  });

  console.log(user, ":::::::::::: USER :::::::::::>>>")
  console.log(auth, ":::::::::::: AUTH :::::::::::>>>")

  const handleSelectedStatus = ({ status }: any) => {
    setSelectedStatus(status);
    setDetailsPanel(true);
  };

  const handleDateChange = async (data: any) => {
    setDates(data);
  };

  useEffect(() => {}, [dates, station]);
  return (
    <div className="">
      <Header stateHandler={handleDateChange} />
      <div className="m-2">
        <div className="grid grid-cols-12 gap-2 mb-4">
          <Completed
            handleSelectedStatus={handleSelectedStatus}
            station={station}
            dates={dates}
          />
          <Inprogress
            handleSelectedStatus={handleSelectedStatus}
            station={station}
            dates={dates}
          />
          <Awaiting
            handleSelectedStatus={handleSelectedStatus}
            station={station}
            dates={dates}
          />
          <Paused
            handleSelectedStatus={handleSelectedStatus}
            station={station}
            dates={dates}
          />
        </div>

        <Technicians station={station} dates={dates} />

        <div className="grid grid-cols-12 gap-x-2">
          <div className="col-span-12 sm:col-span-4 bg-white rounded-lg">
            <Reasons station={station} dates={dates} />
          </div>
          <div className="col-span-12 sm:col-span-2 bg-white rounded-lg">
            <Attendance />
          </div>

          <div className="col-span-12 sm:col-span-6 bg-white rounded-lg">
            <Availability />
          </div>

        </div>

        <JobcardDetails
          isVisible={detailsPanel}
          setVisible={setDetailsPanel}
          selectedStatus={selectedStatus}
          station={station}
          dates={dates}
        />
      </div>
    </div>
  );
}
