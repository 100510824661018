import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, message, Select } from "antd";
import { handleFetchTechnicians } from "../../../../api/technicians";
import {  handleUpdateJobcardItem } from "../../../../api/jobcards";

const { Option } = Select;

export default function AssignTechnician({ isVisible, setVisible, jobcard, item, id }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { data: technicians } = useQuery({
    queryKey: ["technicians"],
    queryFn: handleFetchTechnicians,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateJobcardItem,
    onSuccess: () => {
      message.success("Assigned Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["wips"] });
      queryClient.invalidateQueries({ queryKey: ["jobcards", id] });
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { technician } = await form.getFieldsValue();
    const data = {
      technician,
    };
    await mutate({ _id: id, itemId: item?._id,  data });
    setVisible(false);
  };

  const technicianOptions = technicians?.data?.map(
    (technician: any, index: number) => (
      <Option value={technician?._id} key={index}>
        {technician?.name}
      </Option>
    )
  );

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Assign Technician"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="assignTechnician"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Assign
        </Button>,
      ]}
    >
      <Form
        id="assignTechnician"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="technician"
              label={"Technician"}
              rules={[
                {
                  required: true,
                  message: "Please select technician",
                },
              ]}
            >
              <Select placeholder="Technician">{technicianOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
