import { Calendar, ConfigProvider, message, Table } from "antd";
import BackNav from "../../../components/toolbars/BackNav";
import { useState } from "react";
import Something from "../../../components/shared/Something";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  handleFetchAttendance,
  handleFillAttendance,
} from "../../../api/attendance";
import CreateAttendance from "./components/CreateAttendance";
import type { Dayjs } from "dayjs";
import type { CalendarProps } from "antd";
import moment from "moment";
import { GoCheckbox } from "react-icons/go";
import { AiOutlineWarning } from "react-icons/ai";
import 'moment/locale/en-gb';
import disabledWend from "../../../utils/disabledWend";
import Availability from "./components/Availability";


moment.locale('en-gb');
export default function Attendance() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const today = moment(new Date()).format("YYYYMMDD");
  const [date, setDate] = useState<any>(today);
  const queryClient = useQueryClient();

  const {
    data: attendance,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["attendances", date],
    queryFn: () => handleFetchAttendance({ date }),
  });

  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const onDateChange = (value: any) => {
    setDate(value.format("YYYYMMDD"));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: handleFillAttendance,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["attendances"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleAssignAttendance = async ({ isPresent, record }: any) => {
    await mutate({
      data: { isPresent, isFilled: true },
      _id: attendance?.data?._id,
      technicianId: record?._id,
    });
  };

  const columns = [
    {
      title: "S/N",
      width: 20,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">{index + 1}</span>
      ),
    },
    {
      title: "CODE",
      width: 30,
      render: (record: any) => (
        <span className="text-gray-700 capitalize">{record?.code || "-"}</span>
      ),
    },
    {
      title: "NAME",
      width: 150,
      render: (record: any) => (
        <div className="flex items-center">
          <div className="">
            <img
              src={record?.avatar}
              alt=""
              style={{
                width: 40,
              }}
              className="w-full aspect-[4/4] object-contain"
            />
          </div>

          <div className="flex flex-col">
            <div className="text-black">{record?.name}</div>
            <div className="text-gray-600 text-xs">
              {moment(date).format("LL")}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "ACTION",
      width: 50,
      render: (record: any) => (
        <div className="flex items-center">
          {record?.isFilled ? (
            <div className="">
              {record?.isPresent ? (
                <div className="text-green-500 flex items-center">
                  <GoCheckbox />
                  <span className="mx-1">Present</span>
                </div>
              ) : (
                <div className="text-red-500 flex items-center">
                  <AiOutlineWarning />
                  <span className="mx-1">Absent</span>
                </div>
              )}
            </div>
          ) : (
            <div className="flex m-2">
              <button
                onClick={() => {
                  handleAssignAttendance({
                    isPresent: true,
                    record,
                  });
                }}
                className="flex justify-center items-center mx-1 text-gray-700 border border-green-600 rounded-sm px-2 bg-green-200 hover:bg-green-300 cursor-pointer"
              >
                Present
              </button>
              <button
                onClick={() => {
                  handleAssignAttendance({
                    isPresent: false,
                    record,
                  });
                }}
                className="flex justify-center items-center text-gray-700 border border-red-600 rounded-sm px-2 bg-red-200 hover:bg-red-300 cursor-pointer"
              >
                Absent
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage`} />
      <div className="bg-white m-1">
        <div className="flex justify-between border-b p-2">
          <div className="">
            <div className="font-bold">Technician Attendance</div>
            <div className="text-gray-600">Manage Technician Attendance</div>
          </div>
          <div className="">
            <button
              onClick={() => setCreateModal(true)}
              className="flex mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-gray-100"
            >
              + New
            </button>
          </div>
        </div>

        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid grid-cols-12 gap-x-2">
            <div className="col-span-12 sm:col-span-4">
              <Calendar
                fullscreen={false}
                onPanelChange={onPanelChange}
                onChange={onDateChange}
                disabledDate={disabledWend}
              />
            </div>
            <div className="col-span-12 sm:col-span-8">
              <div className="border-b mb-2">
                <div className="text-3xl">ATTENDANCE</div>
                <div className="text-lg uppercase text-green-600">
                  {moment(date).format("Do MMM YYYY")}
                </div>
              </div>
              {attendance?.data?.technicians.length > 0 ? (
                <div className="">
                  <Table
                    columns={columns}
                    dataSource={attendance?.data?.technicians || []}
                    size="small"
                    bordered
                    pagination={{ pageSize: 20 }}
                    scroll={{ y: 525 }}
                    loading={isLoading}
                    rowKey="id"
                  />
                  {/* {today === date && (
                    <div className="">
                      <Availability />
                    </div>
                  )} */}
                </div>
              ) : (
                <div className="">
                  <div className="text-center my-8">No Attendance</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <CreateAttendance isVisible={createModal} setVisible={setCreateModal} />
    </div>
  );
}
