import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchSkills = async () => {
  const uri = `skills`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateSkill = async (data: any) => {
  const uri = `skills`;
  const response = postData({ data, uri });
  return response;
};

const handleUpdateSkill = async ({ data, _id }: any) => {
  const uri = `skills/${_id}`;
  const response = putData({ data, uri });
  return response;
};

const handleDeleteSkill = async ({ _id }: any) => {
  const uri = `skills/${_id}`;
  const response = deleteData({ uri });
  return response;
};

export {
  handleFetchSkills,
  handleCreateSkill,
  handleUpdateSkill,
  handleDeleteSkill,
};
