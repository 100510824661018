import getTimeHrs from "./getTimeHrs";

const getJobHrs = (items: any) => {
  let hrs: any = 0;
  items?.forEach((item: any) => {

    item.tasks?.forEach((task: any) => {
      task?.actions?.forEach((item: any) => {
        hrs += getTimeHrs(item.startTime, item.endTime);
      });
    });
  })
  return parseFloat(hrs).toFixed(1)
  
};

export default getJobHrs;
