import { Link } from "react-router-dom";
import user from "../../components/icons/user.svg";
import technician from "../../components/icons/technician.svg";
import jobcards from "../../components/icons/jobcards.svg";
import barcode from "../../components/icons/barcode.svg";
import skills from "../../components/icons/skills.svg";
import lock from "../../components/icons/lock.svg";
import warehouse from "../../components/icons/warehouse.svg";
import models from "../../components/icons/models.svg";
import reason from "../../components/icons/reason.svg";
import teams from "../../components/icons/teams.svg";
import calendar from "../../components/icons/calendar.svg";
import PageNav from "../../components/toolbars/PageNav";

export default function Manage() {
  const data = [
    {
      id: 1,
      name: "RTS Codes",
      link: "/manage/rtscodes",
      bg: "bg-blue-100",
      icon: <img src={barcode} width={80} height={80} />,
    },
    {
      id: 2,
      name: "Generic Codes",
      link: "/manage/generics",
      bg: "bg-gray-100",
      icon: <img src={barcode} width={80} height={80} />,
    },
    {
      id: 3,
      name: "Reasons",
      link: "/manage/reasons",
      bg: "bg-gray-100",
      icon: <img src={reason} width={80} height={80} />,
    },
    {
      id: 4,
      name: "Technicians",
      link: "/manage/technicians",
      bg: "bg-gray-100",
      icon: <img src={technician} width={80} height={80} />,
    },
    {
      id: 5,
      name: "Attendance",
      link: "/manage/attendance",
      bg: "bg-gray-100",
      icon: <img src={calendar} width={80} height={80} />,
    },
    {
      id: 6,
      name: "Teams",
      link: "/manage/teams",
      bg: "bg-gray-50",
      icon: <img src={teams} width={80} height={80} />,
    },
    {
      id: 7,
      name: "Skills",
      link: "/manage/skills",
      bg: "bg-gray-100",
      icon: <img src={skills} width={80} height={80} />,
    },

    {
      id: 8,
      name: "Models",
      link: "/manage/models",
      bg: "bg-gray-100",
      icon: <img src={models} width={80} height={80} />,
    },
    {
      id: 9,
      name: "Stations",
      link: "/manage/stations",
      bg: "bg-gray-100",
      icon: <img src={warehouse} width={80} height={80} />,
    },

    {
      id: 10,
      name: "Users",
      link: "/manage/users",
      bg: "bg-gray-100",
      icon: <img src={user} width={80} height={80} />,
    },
    {
      id: 11,
      name: "Roles",
      link: "/manage/roles",
      bg: "bg-gray-100",
      icon: <img src={lock} width={80} height={80} />,
    },


  ];
  return (
    <div className="bg-white min-h-screen">
      <PageNav title={"Manage"} link={"/manage"} />
      <div className="m-1">
        <div className="grid grid-cols-12 gap-1">
          <Link
            to={`/manage/jobcards`}
            className="col-span-12 sm:col-span-2 bg-green-100 p-4 rounded-md mx-1 mt-1 sm:mx-1 sm:my-1 flex justify-center items-center border"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="">
                <img src={jobcards} width={140} height={140} />
              </div>
              <div className="font-bold text-xl">Jobcards</div>
            </div>
          </Link>
          <div className="col-span-12 sm:col-span-10">
            <div className="grid grid-cols-12 gap-1">
              {data?.map((item: any, index: number) => (
                <Link
                  to={`${item?.link}`}
                  className={`col-span-6 sm:col-span-3 lg:col-span-2 p-4 rounded-md mx-1 mt-1 sm:mx-1 sm:my-1 border ${item.bg}`}
                  key={index}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div className="">{item?.icon}</div>
                    <div className="font-bold">{item?.name}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
