import LargeItem from "./LargeItem";

export default function LargeMenu() {
  const menus = [
    {
      id: 1,
      access: [1, 2, 3, 9],
      path: "/dashboard",
      name: "Dashboard",
    },
    {
      id: 1,
      access: [1, 2, 3, 9],
      path: "/station",
      name: "Station",
    },
    {
      id: 1,
      access: [1, 2, 3, 9],
      path: "/manage",
      name: "Manage",
    },
  ];
  return (
    <div className="flex sm:h-16">
      {menus?.map((menu: any, index: number) => {
        if (menu?.access?.includes(1)) {
          return <LargeItem menu={menu} key={index} />;
        }
      })}
    </div>
  );
}
