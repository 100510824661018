import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";
import putData from "../services/putData";


const handleFetchTeams = async () => {
  const uri = `teams`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateTeam = async (data: any) => {
  const uri = `teams`;
  const response = postData({ data, uri });
  return response;
};


const handleUpdateTeam = async ({ _id, data }: any) => {
  const uri = `teams/${_id}`;
  const response = putData({ data, uri });
  return response;
};


const handleDeleteTeam = async ({_id, data}: any) => {
  const uri = `teams/${_id}`;
  const response = deleteData({ data, uri });
  return response;
};

const handleAssignTeamMember = async ({_id, data}: any) => {
  const uri = `teams/${_id}/assign/member`;
  const response = patchData({ data, uri });
  return response;
};


const handleAssignTeamLeader = async ({_id, data}: any) => {
  const uri = `teams/${_id}/assign/leader`;
  const response = patchData({ data, uri });
  return response;
};


export {
  handleFetchTeams,
  handleCreateTeam,
  handleUpdateTeam,
  handleDeleteTeam,
  handleAssignTeamMember,
  handleAssignTeamLeader
};
