import  { useEffect, useState } from "react";

const IdleTime = ({ start, end }: any) => {
  const [timeDiff, setTimeDiff] = useState("");

  useEffect(() => {

    const calculateTimeDifference = () => {
      const diffInMs = new Date(end).getTime() - new Date(start).getTime();

      // Convert ms to hours and minutes
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

      setTimeDiff(`${diffInHours}hrs ${diffInMinutes}mins`);
    };

    calculateTimeDifference();
  }, [start, end]);

  return <div><span className="font-bold">Delay  :</span> {timeDiff}</div>;
};

export default IdleTime;
