import { useEffect, useState } from "react";
import getItemHrs from "../../../../utils/getItemHrs";
import getEfficiency from "../../../../utils/getEfficiency";
import getTaskHrs from "../../../../utils/getTaskHrs";
import getPercent from "../../../../utils/getPercent";
import { Tooltip, message } from "antd";
import { GoIssueReopened } from "react-icons/go";
import { IoMdCheckboxOutline } from "react-icons/io";
import { GrCirclePlay } from "react-icons/gr";
import { FaRegPauseCircle } from "react-icons/fa";
import { BsStopCircle } from "react-icons/bs";
import { MdMoreTime } from "react-icons/md";

import Moment from "react-moment";
import getTimeHrs from "../../../../utils/getTimeHrs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  handleCloseJobcardItemTask,
  handleReOpenJobcardItemTask,
  handleStartJobcardItemTask,
} from "../../../../api/jobcards";
import { bgColors } from "../../../../utils/getColors";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import DeleteJobItem from "./DeleteJobItem";
import DeleteItemTask from "./DeleteItemTask";
import AddItemTask from "./AddItemTask";
import { TbEdit } from "react-icons/tb";
import UpdateJobItem from "./UpdateJobItem";
import UpdateItemTask from "./UpdateItemTask";
import ActionHumanTime from "./ActionHumanTime";
import TaskHumanTime from "./TaskHumanTime";
import { AiOutlineUserSwitch } from "react-icons/ai";
import AssignTechnician from "./AssignTechnician";
import { LuTimerOff } from "react-icons/lu";
import getTaskPausedHrs from "../../../../utils/getTaskPausedHrs";
import TaskPausedHumanTime from "./TaskPausedHumanTime";

export default function LineItem({ item, index, jobcard, id, editMode, handlePauseTask, handleEODTask }: any) {
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedTask, setSelectedTask] = useState<any>({});
  const [updateItemModal, setUpdateItemModal] = useState<boolean>(false);
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);

  const [createTaskModal, setCreateTaskModal] = useState<boolean>(false);
  const [updateTaskModal, setUpdateTaskModal] = useState<boolean>(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState<boolean>(false);

  const [updateTechModal, setUpdateTechModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { mutate: startJobcardTask } = useMutation({
    mutationFn: handleStartJobcardItemTask,
    onSuccess: () => {
      message.success("Started Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", jobcard?._id] });
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });



  const { mutate: CloseJobcardTask } = useMutation({
    mutationFn: handleCloseJobcardItemTask,
    onSuccess: () => {
      message.success("Finished Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", jobcard?._id] });
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: reOpenJobcardTask } = useMutation({
    mutationFn: handleReOpenJobcardItemTask,
    onSuccess: () => {
      message.success("Re-Opened Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", jobcard?._id] });
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleReOpenJobcardTask = async ({ itemId, taskId }: any) => {
    await reOpenJobcardTask({ _id: jobcard?._id, itemId, taskId });
  };

  const handleStartJobcardTask = async ({ itemId, taskId }: any) => {
    await startJobcardTask({ _id: jobcard?._id, itemId, taskId });
  };


  const handlePauseJobcardTask = async ({ item, task }: any) => {
    await handlePauseTask({ _id: jobcard?._id, item, task });
  };


  const handleEodJobcardTask = async ({ item, task }: any) => {
    await handleEODTask({ _id: jobcard?._id, item, task });
  };




  const handleCloseJobcardTask = async ({ itemId, taskId }: any) => {
    await CloseJobcardTask({ _id: jobcard?._id, itemId, taskId });
  };

  useEffect(() => {}, [item]);
  return (
    <div className="mb-4">
      <div className="flex justify-between">
        <div className="flex flex-col truncate w-3/4">
          <div className="flex font-bold text-3xl truncate">
            <span className="mr-1" style={{ color: bgColors[item?.status] }}>
              TASK {index + 1}.
            </span>
            <div className="mx-3">
              {item?.rtscode} : {item?.description}
            </div>
          </div>
          <button
            onClick={() => {
              setSelectedItem(item);
              setUpdateTechModal(true);
            }}
            className="flex items-center"
          >
            <div className="flex items-center mr-2">
              <div className="font-bold mr-1">Technician: </div>
              {item?.technician?.name ? (
                <div className="flex items-center">
                  {item?.technician?.name}
                  <div className="ml-1">
                    <AiOutlineUserSwitch />
                  </div>
                </div>
              ) : (
                <div className="bg-gray-200 py-1 px-2">Assign Technician</div>
              )}
            </div>
          </button>
        </div>
        <div className="flex flex-col items-end w-1/4">
          <div className="flex items-baseline">
            <div className="flex items-baseline mx-2">
              <span className="text-4xl font-bold">
                {getItemHrs(item?.tasks)}
              </span>
              <span> / {item?.expectedHours}</span>
            </div>
            <div className="">
              [{"Efficiency :  "}
              {getEfficiency(getItemHrs(item?.tasks), item?.expectedHours)} % ]
            </div>
          </div>
          {editMode && (
            <div className="flex items-center">
              <Tooltip title="Edit Task">
                <button
                  onClick={() => {
                    setSelectedItem(item);
                    setUpdateItemModal(true);
                  }}
                  className="flex bg-yellow-50 mx-2 justify-center items-center border px-4 py-1 w-14 hover:bg-yellow-100"
                >
                  <TbEdit size={22} />
                </button>
              </Tooltip>
              <Tooltip title="Delete Task">
                <button
                  onClick={() => {
                    setSelectedItem(item);
                    setDeleteItemModal(true);
                  }}
                  className="flex bg-red-50 justify-center items-center border px-4 py-1 w-14 hover:bg-red-100"
                >
                  <RiDeleteBinLine size={20} />
                </button>
              </Tooltip>
              <Tooltip title="Add Sub-Task">
                <button
                  onClick={() => {
                    setSelectedItem(item);
                    setCreateTaskModal(true);
                  }}
                  className="flex bg-green-50 ml-2 justify-center items-center border px-4 py-1 w-14 hover:bg-green-100"
                >
                  <FiPlus size={20} />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="">
        {item?.tasks?.map((task: any, index: number) => (
          <div className="ml-4 mb-8" key={index}>
            <div className="flex flex-col justify-between px-2">
              <div className="flex justify-between text-2xl">
                <div className="flex">
                  <div className="font-bold">SUB-TASK {index + 1}.</div>
                  <div className="mx-2">{task?.description}</div>
                </div>
                <div className="flex items-center">
                  <div
                    className="uppercase"
                    style={{ color: bgColors[task?.status] }}
                  >
                    {task?.status}
                  </div>
                  {editMode && (
                    <div className="flex items-center">
                      <Tooltip title="Edit Sub-Task">
                        <button
                          onClick={() => {
                            setSelectedTask(task);
                            setSelectedItem(item);
                            setUpdateTaskModal(true);
                          }}
                          className="flex bg-yellow-50 ml-2 justify-center items-center border px-4 py-1 w-14 hover:bg-yellow-100"
                        >
                          <TbEdit size={22} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete Sub-Task">
                        <button
                          onClick={() => {
                            setSelectedTask(task);
                            setSelectedItem(item);
                            setDeleteTaskModal(true);
                          }}
                          className="flex bg-red-50 ml-2 justify-center items-center border px-4 py-1 w-14 hover:bg-red-100"
                        >
                          <RiDeleteBinLine size={20} />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-start justify-between border-t border-b py-1">
                <div className="flex">
                  <div className="flex justify-end mr-4 py-2">
                    <div className="flex">
                      <div className="">Time Taken: </div>
                      <div className="w-6 text-right mx-2">
                        {getTaskHrs(task?.actions)}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mx-4 py-2">
                    <div className="flex">
                      <div className="">Allowed Time : </div>
                      <div className="w-6 text-right mx-2">
                        {task?.expectedHours}
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 flex justify-end py-2">
                    [
                    <span className="mx-1">
                      Time Taken:{" "}
                      {getPercent(
                        getTaskHrs(task?.actions),
                        task?.expectedHours
                      )}{" "}
                      %{" "}
                    </span>
                    <span className="mx-1">,</span>
                    <span className="mx-1">
                      Efficiency:
                      {getEfficiency(
                        getTaskHrs(task?.actions),
                        task?.expectedHours
                      )}
                      %
                    </span>
                    ]
                  </div>
                </div>
                <div className="flex">
                  {task?.isClosed ? (
                    <div className="flex items-center">
                      <Tooltip placement="top" title={"Reopen Task"}>
                        <button
                          onClick={() =>
                            handleReOpenJobcardTask({
                              itemId: item?._id,
                              taskId: task?._id,
                            })
                          }
                          className="bg-gray-100  py-1 px-2 hover:bg-gray-200 mx-2 flex items-center border shadow-md"
                        >
                          <GoIssueReopened size={20} />
                          <span className="mx-1">Re-Open</span>
                        </button>
                      </Tooltip>

                      <div className="bg-green-600 py-1 px-2 border">
                        <div className="flex items-center text-white">
                          <IoMdCheckboxOutline size={22} />
                          <span className="ml-2">Completed</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                       {/* <button
                            onClick={
                              () => {
                                handleEodJobcardTask({
                                  item: item,
                                  task: task,
                                })
                              }
                            }
                            className="mx-2 bg-gray-100 py-1 px-2 flex items-center border shadow-md hover:bg-yellow-100"
                          >
                            <span className="mx-1">EOD</span>
                          </button> */}
                      {task?.isPaused ? (
                        <Tooltip placement="top" title={"Start Task"}>
                          <button
                            onClick={() =>
                              handleStartJobcardTask({
                                itemId: item?._id,
                                taskId: task?._id,
                              })
                            }
                            className="mx-2 bg-gray-100 py-1 px-2 flex items-center border shadow-md hover:bg-blue-100"
                          >
                            <GrCirclePlay size={24} color={"#000000"} />
                            <span className="mx-1">Start</span>
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip placement="top" title={"Pause Task"}>
                          <button
                            onClick={
                              () => {
                                handlePauseJobcardTask({
                                  item: item,
                                  task: task,
                                })
                              }
                            }
                            className="mx-2 bg-gray-100 py-1 px-2 flex items-center border shadow-md hover:bg-yellow-100"
                          >
                            <FaRegPauseCircle size={24} color={"#dc2626"} />
                            <span className="mx-1">Pause</span>
                          </button>

                         
                        </Tooltip>
                      )}

                      <Tooltip placement="top" title={"Complete Task"}>
                        <button
                          onClick={() =>
                            handleCloseJobcardTask({
                              itemId: item?._id,
                              taskId: task?._id,
                            })
                          }
                          className="bg-gray-100  py-1 px-2 flex items-center border shadow-md hover:bg-green-100"
                        >
                          <BsStopCircle size={24} color={"#323232"} />
                          <div className="mx-2">Complete</div>
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {task?.actions?.length > 0 && (
              <div className="mx-2">
                <div className="flex justify-between border-t border-b px-2 bg-gray-50">
                  <div className="flex font-bold">
                    <div className="w-8">S/N</div>
                    <div className="w-28 text-center">ACTION</div>
                    <div className="w-48">DESCRIPTION</div>
                    <div className="w-44 text-center">START</div>
                    <div className="w-44 text-center">FINISH</div>
                  </div>
                  <div className="flex justify-between px-2 border-l w-48">
                    <div className="">(HRS)</div>
                    <div className="">TIME TAKEN</div>
                  </div>
                </div>
                <div className="">
                  {task?.actions?.map((action: any, index: number) => (
                    <div
                      className={`flex justify-between px-2 border-b ${
                        action?.isPause ? `${action?.isEOD ? "bg-red-100 text-red-400": "bg-gray-100 text-gray-400"}` : ""
                      } `}
                      key={index}
                    >
                      <div className={`flex `}>
                        <div className="w-8">{index + 1}.</div>
                        <div className="w-28 flex items-center capitalize">
                          <div className="">
                            {action?.isPause ? (
                              <div className="flex items-center">
                                <LuTimerOff color="#FACC16" />
                                <div className="mx-1 w-10">Paused</div>
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <div className="w-4">
                                  <MdMoreTime color="#16a34a" />
                                </div>
                                <div className="mx-1 w-10">Worked</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-48 flex items-center capitalize">
                          {action?.name || "Work Session"}
                        </div>
                        <div className="w-44 flex justify-center items-center">
                          <Moment format="DD/MM/YYYY">
                            {action?.startTime}
                          </Moment>
                          <div className="mx-2"> . </div>
                          <Moment format="HH:mm" className="font-bold">
                            {action?.startTime}
                          </Moment>
                        </div>
                        <div className="">
                          {action?.endTime ? (
                            <div className="w-44 flex justify-center items-center">
                              <Moment format="DD/MM/YYYY">
                                {action?.endTime}
                              </Moment>
                              <div className="mx-2"> . </div>

                              <Moment format="HH:mm" className="font-bold">
                                {action?.endTime}
                              </Moment>
                            </div>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between border-l px-2 w-48">
                        <div className="">
                          {getTimeHrs(action?.startTime, action?.endTime)}
                        </div>
                        <div className="">
                          <ActionHumanTime
                            startTime={action?.startTime}
                            endTime={action?.endTime}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between items-center border-t-2 border-t-gray-400 border-b px-2 font-bold">
                  <div className="flex flex-1 justify-end px-2">Total Hours Worked</div>
                  <div className="flex border-l px-2 w-48">
                    <div className="w-16">{getTaskHrs(task?.actions)}</div>
                    <div className="w-32">
                      <TaskHumanTime actions={task?.actions} />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center border-t-2 border-b px-2 font-bold">
                <div className="flex flex-1 justify-end px-2">Total Hours Paused</div>
                  <div className="flex border-l px-2 w-48">
                    <div className="w-16">{getTaskPausedHrs(task?.actions)}</div>
                    <div className="w-32">
                      <TaskPausedHumanTime actions={task?.actions} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <DeleteJobItem
        isVisible={deleteItemModal}
        setVisible={setDeleteItemModal}
        jobcard={jobcard}
        item={selectedItem}
      />
      <DeleteItemTask
        isVisible={deleteTaskModal}
        setVisible={setDeleteTaskModal}
        jobcard={jobcard}
        item={selectedItem}
        task={selectedTask}
      />

      <AddItemTask
        isVisible={createTaskModal}
        setVisible={setCreateTaskModal}
        jobcard={jobcard}
        item={selectedItem}
      />

      <UpdateJobItem
        isVisible={updateItemModal}
        setVisible={setUpdateItemModal}
        jobcard={jobcard}
        item={selectedItem}
      />

      <UpdateItemTask
        isVisible={updateTaskModal}
        setVisible={setUpdateTaskModal}
        jobcard={jobcard}
        item={selectedItem}
        task={selectedTask}
      />

      <AssignTechnician
        isVisible={updateTechModal}
        setVisible={setUpdateTechModal}
        jobcard={jobcard}
        item={selectedItem}
        id={id}
      />
    </div>
  );
}
