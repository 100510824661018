import { useEffect } from "react";

export default function CarryOvers({
  station,
  count,
  dates,
  jobcards,
  handleSelectedStatus,
}: any) {
  const filtered = jobcards?.data?.filter(
    (jobcard: any) => jobcard?.isCarriedOver === true
  );

  useEffect(() => {}, [station, dates]);
  return (
    <button
      className="col-span-12  flex justify-between"
      onClick={() =>
        handleSelectedStatus({ status: "carry overs", jobcards: filtered })
      }
    >
      <div className="col-span-12  border-t border-b border-l border-gray-400 flex justify-between py-2 px-4 rounded-l-md">
        <div className="">Total</div>
        <div className="px-2 mx-2 border-l border-gray-400  flex justify-center items-center">
          {jobcards?.data?.length + count || 0}
        </div>
      </div>

      <div className="col-span-12  flex justify-between py-2 px-4 bg-red-200 border-t border-b border-r border-l border-red-300 rounded-r-md">
        <div className="">Carry Overs</div>
        <div className="px-2 mx-2 border-l border-gray-400  flex justify-center items-center">
          {filtered?.length || 0} / {jobcards?.data?.length + count || 0}
        </div>
      </div>
    </button>
  );
}
