import { Button, Modal, Form, Input, Row, Col, Select, message } from "antd";
import { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import getBase64 from "../../../../utils/getBase64";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUploadJobcardDoc } from "../../../../api/jobcards";
const { Option } = Select;

export default function UploadDocument({
  isVisible,
  setVisible,
  jobcard,
  _id,
}: any) {
  const [isSelected, setIsSelected] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: handleUploadJobcardDoc,
    onSuccess: (res) => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards"] });
      queryClient.invalidateQueries({ queryKey: ["jobcards", _id] });
      setVisible(false);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const { description, otherdescription } = await form.validateFields();

    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const data = {
      document: base64,
      filetype: type,
      filesize: size,
      description: isOther ? otherdescription : description,
    };
    await mutate({ _id, data });
  };

  const handleSelected = (e: any) => {
    if (e === "Other") {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  }, [jobcard]);

  return (
    <Modal
      open={isVisible}
      title="Upload Document"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="uploadJobcardDocument"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="uploadJobcardDocument"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              label={"Document Description"}
              rules={[
                {
                  required: true,
                  message: "Please select description",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search and Select Description"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(e) => handleSelected(e)}
              >
                <Option value="Jobcard">Jobcard</Option>
                <Option value="Other">Other ( Specify )</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {isOther && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="otherdescription"
                label={"Other Description"}
                rules={[
                  {
                    required: true,
                    message: "Please enter description",
                  },
                ]}
              >
                <Input type="text" placeholder="Document Description"></Input>
              </Form.Item>
            </Col>
          </Row>
        )}
        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Document Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Document"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Document"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
