import { TbEdit } from "react-icons/tb";
import BackNav from "../../../components/toolbars/BackNav";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { FaCheck } from "react-icons/fa6";

import {
  handleFetchJobcard,
  handleUpdateJobcardStation,
} from "../../../api/jobcards";
import { Popover, message } from "antd";
import { GoPlus } from "react-icons/go";
import { useContext, useState } from "react";
import AddLineItem from "./components/AddLineItem";
import UpdateJobcard from "./components/UpdateJobcard";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteJobcard from "./components/DeleteJobcard";
import Moment from "react-moment";
import { handleFetchStations } from "../../../api/stations";
import { AuthContext } from "../../../contexts/AuthContext";
import LineItem from "./components/LineItem";
import { IoChevronDownSharp } from "react-icons/io5";
import UpdateStatus from "./components/UpdateStatus";
import { GrLocation } from "react-icons/gr";
import { GoChevronRight } from "react-icons/go";

import { IoFolderOutline } from "react-icons/io5";

import Documents from "./components/Documents";
import UpdateGeneric from "./components/UpdateGeneric";
import AddReason from "./components/AddReason";
import AddEOD from "./components/AddEOD";

export default function Jobcard() {
  const { updateStation } = useContext(AuthContext);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [docsModal, setDocsModal] = useState<boolean>(false);
  const [addItemModal, setAddItemModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [genericModal, setGenericModal] = useState<boolean>(false);
  const [reasonTaskModal, setReasonTaskModal] = useState<boolean>(false);
  const [eodTaskModal, setEodTaskModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedTask, setSelectedTask] = useState<any>({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: jobcard, refetch } = useQuery({
    queryKey: ["jobcards", id],
    queryFn: () => handleFetchJobcard({ _id: id }),
  });

  const { data: stations } = useQuery({
    queryKey: ["stations"],
    queryFn: handleFetchStations,
  });

  const handleSelectStation = (station: any) => {
    const selected = stations?.data.find((s: any) => s?._id === station);
    updateStation(selected);
  };

  const { mutate: updateThisStation } = useMutation({
    mutationFn: handleUpdateJobcardStation,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["jobcards", id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handlePauseTask = ({ item, task }: any) => {
    setSelectedTask(task);
    setSelectedItem(item);
    setReasonTaskModal(true);
  };

  const handleEODTask = ({ item, task }: any) => {
    setSelectedTask(task);
    setSelectedItem(item);
    setEodTaskModal(true);
  };

  const handleUpdateStation = async (station: any) => {
    await updateThisStation({ _id: id, data: { station } });
    handleSelectStation(station);
  };

  const site = (
    <div>
      {stations?.data?.map((item: any, index: number) => (
        <div
          className="border-t py-1 hover:bg-gray-100 cursor-pointer"
          key={index}
          onClick={() => handleUpdateStation(item?._id)}
        >
          {item?.name}
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-gray-100">
      <BackNav link={`/manage/jobcards`} />

      <div className="">
        <div className="bg-white m-1">
          <div className="flex justify-between border-b p-2">
            <div className="flex">
              <div className="flex flex-col w-full">
                <div className="flex items-start">
                  <div className="flex flex-col justify-center">
                    <div className="text-2xl font-bold uppercase text-sky-600">
                      Customer: {jobcard?.data?.customer}
                    </div>

                    <div className="flex">
                      <div className="text-gray-600">
                        JOBCARD -{" "}
                        <span className="font-bold">
                          WIP : {jobcard?.data?.wip}
                        </span>
                      </div>
                      <div className="mx-2">
                        Vehicle:{" "}
                        <span className="font-bold">
                          {jobcard?.data?.registration}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="text-gray-700 text-center mx-2 mt-1">
                    <Popover
                      placement="bottom"
                      title={"Assign  Station"}
                      content={site}
                    >
                      {jobcard?.data?.station ? (
                        <div className="flex items-center">
                          <div className="px-2 py-1 hover:bg-gray-300 border-l">
                            <GrLocation />
                          </div>
                          <div>{jobcard?.data?.station?.name}</div>
                          <div className="mx-2">
                            <IoChevronDownSharp size={22} />
                          </div>
                        </div>
                      ) : (
                        <div className="bg-gray-100 px-2 py-1 hover:bg-gray-300">
                          Assign Station
                        </div>
                      )}
                    </Popover>
                  </div>
                </div>
                {jobcard?.data?.description ? (
                  <div className="">{jobcard?.data?.description}</div>
                ) : (
                  <div className="">
                    <button
                      onClick={() => setGenericModal(true)}
                      className="border bg-gray-200 px-2 py-1"
                    >
                      Add Closing Notes
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setStatusModal(true)}
                className="flex bg-blue-50 mx-2 justify-center items-center border px-4 py-1 h-10 hover:bg-blue-100"
              >
                <span>Status:</span>
                <span className="capitalize mx-2">{jobcard?.data?.status}</span>
                <IoChevronDownSharp size={22} />
              </button>
              {editMode && (
                <div className="flex items-center">
                  <button
                    onClick={() => setUpdateModal(true)}
                    className="flex bg-yellow-50 mx-2 justify-center items-center border px-4 py-1 h-10 w-14 hover:bg-yellow-100"
                  >
                    <TbEdit size={22} />
                  </button>
                  <button
                    onClick={() => setDeleteModal(true)}
                    className="flex bg-red-50 mr-2 justify-center items-center border px-4 py-1 h-10 w-14 hover:bg-red-100"
                  >
                    <RiDeleteBinLine size={20} />
                  </button>
                </div>
              )}
              <div className="">
                {editMode ? (
                  <div
                    onClick={() => setEditMode(false)}
                    className="bg-green-400 h-10 flex items-center rounded-lg cursor-pointer"
                  >
                    <div className="flex items-center px-1">
                      <div className="rounded-lg mx-1">Edit</div>
                      <div className="rounded-lg bg-white h-8 w-8 flex justify-center items-center">
                        <FaCheck color="#16a34a" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setEditMode(true)}
                    className="bg-gray-200 h-10 flex items-center rounded-lg cursor-pointer"
                  >
                    <div className="flex items-center px-1">
                      <div className="rounded-lg bg-white h-8 w-8 flex justify-center items-center">
                        <FaCheck color="#6b7280" />
                      </div>
                      <div className="rounded-lg mx-1">Edit</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full  px-2">
            <div className="flex py-2 flex-1">
              <div className="text-gray-700">
                <div className="">
                  <span>Start Date</span>
                  <span>
                    :{" "}
                    <Moment format="Do MMM YYYY">
                      {jobcard?.data?.datein}
                    </Moment>
                  </span>
                </div>
                <div className="">
                  <span>End Date</span>
                  <span>
                    :{" "}
                    <Moment format="Do MMM YYYY">
                      {jobcard?.data?.dateout}
                    </Moment>
                  </span>
                </div>
              </div>
              <button
                onClick={() => setAddItemModal(true)}
                className="flex justify-center items-center border px-4 py-2 mx-4 bg-gray-200 hover:bg-gray-300"
              >
                <GoPlus size={30} />{" "}
                <span className="ml-1">NEW TASK ( RTS CODE)</span>
              </button>
            </div>
            <button
              onClick={() => setDocsModal(true)}
              className="hover:bg-green-100 cursor-pointer p-1"
            >
              <div className="flex items-center">
                <span className="mx-1">Documents</span>
              </div>
              <div className="flex items-center justify-end text-gray-500">
                <IoFolderOutline />
                <span className="mx-1 text-xs">View all</span>{" "}
                <GoChevronRight />
              </div>
            </button>
          </div>

          <div className="">
            <div className="p-4 bg-white">
              {jobcard?.data?.items?.map((item: any, index: number) => (
                <LineItem
                  item={item}
                  index={index}
                  id={id}
                  key={index}
                  editMode={editMode}
                  handlePauseTask={handlePauseTask}
                  handleEODTask={handleEODTask}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <AddLineItem
        isVisible={addItemModal}
        setVisible={setAddItemModal}
        refetch={refetch}
        jobcard={jobcard}
        _id={id}
      />

      <UpdateJobcard
        isVisible={updateModal}
        setVisible={setUpdateModal}
        refetch={refetch}
        jobcard={jobcard?.data}
        _id={id}
      />

      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        refetch={refetch}
        jobcard={jobcard?.data}
        _id={id}
      />

      <UpdateGeneric
        isVisible={genericModal}
        setVisible={setGenericModal}
        refetch={refetch}
        jobcard={jobcard?.data}
        _id={id}
      />

      <DeleteJobcard
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        jobcard={jobcard?.data}
      />

      <Documents
        isVisible={docsModal}
        setVisible={setDocsModal}
        jobcard={jobcard?.data}
        _id={id}
      />

      <AddReason
        isVisible={reasonTaskModal}
        setVisible={setReasonTaskModal}
        jobcard={jobcard}
        task={selectedTask}
        item={selectedItem}
        id={id}
      />

      <AddEOD
        isVisible={eodTaskModal}
        setVisible={setEodTaskModal}
        jobcard={jobcard}
        task={selectedTask}
        item={selectedItem}
        id={id}
      />
    </div>
  );
}
