import getTimeHrs from "./getTimeHrs";

const getTaskHrs = (actions: any) => {
  let hrs: any = 0;
  actions?.forEach((action: any) => {
    if(!action?.isPause){
      hrs += getTimeHrs(action.startTime, action.endTime);
    }
  });
  return parseFloat(hrs).toFixed(1)
};

export default getTaskHrs;
