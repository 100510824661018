import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { handleFetchOnDuty } from "../../../api/items";
import { handleFetchAttendance } from "../../../api/attendance";
import Moment from "react-moment";

export default function Availability() {
  const today = moment(new Date()).format("YYYYMMDD");

  const { data: available } = useQuery({
    queryKey: ["available"],
    queryFn: () => handleFetchOnDuty(),
  });

  const { data: attendance } = useQuery({
    queryKey: ["attendances", today],
    queryFn: () => handleFetchAttendance({ date: today }),
  });

  const getIdleTechnicians = () => {
    // Initialize an empty array to store the IDs
    const ids: any = [];

    // Loop through each entry in the "data" array
    available?.data?.forEach((entry: any) => {
      // Loop through each technician in the "technicians" array of the entry
      entry.technicians.forEach((technician: any) => {
        // Push the _id of the technician into the ids array
        ids.push(technician._id);
      });
    });

    const filteredTechnicians = attendance?.data?.technicians.filter(
      (technician: any) => !ids.includes(technician._id)
    );

    // Return the modified data object with the filtered technicians
    return {
      ...attendance,
      technicians: filteredTechnicians,
    };
  };

  const a = getIdleTechnicians();
  console.log(a, ":::::::>>>>>>>>> AAAAA>>>>>");

  return (
    <div>
      <div className="flex justify-between border-b py-3 px-4">
        <div className="">AVAILABILITY</div>
        <div className="uppercase">
          <Moment format="Do MMM YYYY">{today}</Moment>
        </div>
      </div>
      <div className="grid grid-cols-12 bg-white" style={{ height: "300px" }}>
        <div className="col-span-12 sm:col-span-6 bg-white">
          <div className="bg-green-200 p-4 mb-2">On-Duty Technicians</div>
          {available?.data[0]?.technicians?.map((item: any, index: number) => (
            <div className="flex items-center mb-1" key={index}>
              <div className="">
                <img
                  src={item?.avatar}
                  alt=""
                  style={{
                    width: 40,
                  }}
                  className="w-full aspect-[4/5] object-cover"
                />
              </div>

              <div className="flex flex-col">
                <div className="text-black">{item?.name}</div>
                <div className="text-xs text-green-600">On Duty</div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-12 sm:col-span-6 bg-white">
          <div className="bg-red-200 p-4 mb-2">Idle Technicians</div>
          {a?.technicians?.map((item: any, index: number) => (
            <div className="flex items-center mb-1" key={index}>
              <div className="">
                <img
                  src={item?.avatar}
                  alt=""
                  style={{
                    width: 40,
                  }}
                  className="w-full aspect-[4/5] object-cover"
                />
              </div>

              <div className="flex flex-col">
                <div className="text-black">{item?.name}</div>
                <div className="text-xs text-red-600">Idle</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
